<template>
  <v-row class="ma-0 pa-0" dense>
    <v-col class=" py-0 px-0" cols="4">
      <PatientDetailsCard v-if='this.$store.state.detailedUserState'/>
    </v-col>
    <v-col cols="8" class="py-0" style="overflow: hidden; height: 100%; flex-wrap: nowrap;" ref="drawerContainer"
           v-resize="resizeDrawer">
      <v-navigation-drawer
          v-if="selectedVitalsDrawer || selectedWellnessDrawer"
          right
          absolute
          :width='drawerContainer'
          class="z-index5"
      >
        <WellnessDrawer v-if="selectedWellnessDrawer" :selectedSurvey="selectedWellnessDrawer" @close="closeWellness" :wellnessType="selectedWellnessDrawer"></WellnessDrawer>
        <VitalsDrawer v-if="selectedVitalsDrawer" ref="VitalsDrawer" @close="closeDrawer" :vitalsType="selectedVitalsDrawer" :unitSystem="getUnitSystem"></VitalsDrawer>
      </v-navigation-drawer>
      <v-row dense>
        <v-tabs :ripple="false" class="d-flex justify-center align-center background-white overview" :value="activeTab" icons-and-text v-model="tabs">
          <v-tab @click="change('overview')" :ripple="false" class="Overview_tab">
            {{ $t('overview') }}
          </v-tab>
          <v-tab @click="change('monitoringPlan')" :ripple="false" class="Overview_tab">
            {{ $t('monitoring-plans') }}
          </v-tab>
          <v-tab @click="change('notes')" :ripple="false" class="Overview_tab">
            {{ $t('notes') }}
          </v-tab>
        </v-tabs>
          <Overview v-if="$store.state.detailedUserState && activeTab === 'overview'" ref="OverviewCards" :key="rerender" @toggledrawer="toggleDrawer" @toggledrawer2="toggleDrawer2"/>
      </v-row>
      <v-row dense v-if="activeTab === 'overview'">
        <v-col cols="6">
          <OverviewNotifications/>
        </v-col>
        <v-col cols="6">
          <Chat v-if="detailedUser"
                :userToChat="detailedUser.userData"
                :video="video"
                :key="rerender"
                :filter="filterChanged"
                class="chat-class"
          />
        </v-col>
      </v-row>
      <v-card v-if="activeTab === 'monitoringPlan'">
        <MonitoringPlans :computedPatientId="computedPatientId"/>
      </v-card>
      <v-card elevation="0" v-if="activeTab === 'notes'">
        <Notes :detailedUser="detailedUser" :notes="notesFromRepo"/>
      </v-card>
      <PatientDetailsExitConfirmationDialog
          :show-dialog="showPatientDetailsConfirmationDialog"
          @exitPatientFromPatientDetails="exitPatientFromPatientDetails"
          @stayPatientFromPatientDetails="stayPatientFromPatientDetails"
      />
    </v-col>
  </v-row>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import PatientDetailsCard from './PatientDetailsCard.vue';
import OverviewNotifications from '@/views/Client/components/OverviewNotifications.vue';
import Chat from './Chat/Chat.vue';
import Overview from '@/views/Client/components/Overview.vue';
import MonitoringPlans from '@/views/Client/components/MonitoringPlans.vue';
import Notes from '@/views/Client/components/Notes.vue';
import WellnessDrawer from '@/views/DrawerDialogs/WellnessDrawer.vue';
import PatientDetailsExitConfirmationDialog from '../../dialogs/PatientDetailsExitConfirmationDialog.vue';
import VitalsDrawer from '@/views/DrawerDialogs/VitalsDrawer';

export default {
  components: {
    PatientDetailsCard,
    Overview,
    Notes,
    MonitoringPlans,
    WellnessDrawer,
    VitalsDrawer,
    OverviewNotifications,
    Chat,
    PatientDetailsExitConfirmationDialog,
  },
  props: ['video', 'filterChanged'],
  data () {
    return {
      drawerContainer: 0,
      filterChange: false,
      userLoading: true,
      dialog: true,
      selectedVitalsDrawer: false,
      selectedWellnessDrawer: false,
      selectedSurvey: null,
      notificationsLoading: true,
      toggle: null,
      rerender: 1,
      filterChat: 'LAST7',
      tabs: null,
      activeTab: 'overview',
      showPatientDetailsConfirmationDialog: false,
    };
  },
  async mounted () {
    await this.getUserById();
    await this.getMyNotifications();
    this.$store.commit('SET_DETAILED_USER_STATE', true);
    this.startTimers();
    document.addEventListener('mousemove', this.restartTimers);
    if ([3, 4, 7].includes(this.authUser.roleData.id)) {
      await this.checkIfThereIsAciveTimeTracking();
    }
  },
  computed: {
    ...mapState({
      detailedUser: (state) => state.users.detailedUser,
      startTimeTrackingInfo: (state) => state.timetracking.startTimeTrackingInfo,
      notesFromRepo: (state) => state.appointments.notes,
      notifications: (state) => state.notifications.allNotifications,
      notificationsWithUser: (state) => state.notifications.notificationsWithUser,
      filterCase: (state) => state.authentication.filterCase,
      authUser: (state) => state.authentication.userData,
    }),
    ...mapGetters({
      userSettings: 'authentication/getUserSettings',
      roleName: 'authentication/getRole',
    }),
    userTimeZone () {
      return this.detailedUser?.userData.userTimezone;
    },
    computedPatientId () {
      return this.detailedUser.medicalTeamData[0].patientId;
    },
    getUnitSystem () {
      var units = { concentration: '', unitSystem: '' };
      units.unitSystem = this.userSettings.Units.toLowerCase();
      units.concentration = this.userSettings.Concentration.toLowerCase();
      return units;
    },
  },
  watch: {
    filterChat () {
      this.filterChange = true;
    },
  },
  methods: {
    async checkIfThereIsAciveTimeTracking () {
      const getTimeTrackingBody = {
        PatientId: this.detailedUser.userData.id,
      };
      await this.$store.dispatch('timetracking/getNotFinishedWithPatient', getTimeTrackingBody).then(() => {
        if (this.startTimeTrackingInfo.data.length === 0) {
          const startTimeTrackingBody = {
            hospitalId: this.authUser.hospitalAssignedData.id, // get selected hospital, if there are more assigned to the user
            patientId: this.detailedUser.userData.id,
            visitedPageUrl: 'WEB:' + this.$route.name,
          };
          this.$store.dispatch('timetracking/starttrackingwithpatient', startTimeTrackingBody);
        }
      });
    },
    resizeDrawer () {
      this.drawerContainer = this.$refs.drawerContainer.clientWidth;
    },

    toggleDrawer (vitals) {
      this.selectedVitalsDrawer = vitals;
    },
    toggleDrawer2 (wellness) {
      this.selectedWellnessDrawer = wellness;
    },
    closeDrawer () {
      this.selectedVitalsDrawer = false;
    },
    closeWellness () {
      this.selectedWellnessDrawer = false;
    },
    change (tab) {
      this.activeTab = tab;
    },
    async getUserById () {
      if (this.$store.state.users.detailedUser?.userData?.id === parseInt(this.$route.params.id)) {
        this.userLoading = false;
        return;
      }
      this.userLoading = true;
      await this.$store
          .dispatch('users/getUserById', this.$route.params.id)
          .then(() => {
            this.userLoading = false;
          });
    },
    async getMyNotifications () {
      const object = {
        filterCase: this.$cookies.get('datafilter'),
        patientId: parseInt(this.$route.params.id),
      };
      this.notificationsLoading = true;
      await this.$store.dispatch('notifications/getNotificationsWithPatient', object).then(() => {
        this.readedNotication = this.notificationsWithUser.length;
      });
      this.notificationsLoading = false;
    },
    setupBeforeUnloadListener () {
      window.addEventListener('beforeunload', this.handleBeforeUnload);
    },
    removeBeforeUnloadListener () {
      window.removeEventListener('beforeunload', this.handleBeforeUnload);
    },
    startTimers () {
      this.activityTimerId = setTimeout(this.askToCloseComponent, 300000); // 5 minutes
      this.inactivityTimerId = setTimeout(this.autoCloseComponent, 315000); // 5 minutes 15 seconds
    },
    restartTimers () {
      clearTimeout(this.activityTimerId);
      clearTimeout(this.inactivityTimerId);
      this.startTimers();
    },
    askToCloseComponent () {
      this.showPatientDetailsConfirmationDialog = true;
    },
    exitPatientFromPatientDetails () {
      this.showPatientDetailsConfirmationDialog = false;
      this.stopTimeTracking(true);
    },
    stayPatientFromPatientDetails () {
      this.showPatientDetailsConfirmationDialog = false;
      this.restartTimers();
    },
    autoCloseComponent () {
      this.showPatientDetailsConfirmationDialog = false;
      this.stopTimeTracking(true);
    },
    stopTimeTracking (useRouter) {
      if ([3, 4, 7].includes(this.authUser.roleData.id)) {
        const getTimeTrackingBody = {
          PatientId: this.detailedUser.userData.id,
        };
        this.$store.dispatch('timetracking/getNotFinishedWithPatient', getTimeTrackingBody).then(() => {
          if (this.startTimeTrackingInfo.data.length === 0) {
            return;
          }
          const stopTimeTrackingBody = {
            trackingId: this.startTimeTrackingInfo.data[0].id,
          };
          this.$store.dispatch('timetracking/stoptrackingwithpatient', stopTimeTrackingBody).then(() => {
            if (useRouter) {
              this.$router.go(-1);
            }
          });
        });
      }
    },
  },
  beforeDestroy () {
    this.removeBeforeUnloadListener();
    document.removeEventListener('mousemove', this.restartTimers);
    clearTimeout(this.activityTimerId);
    clearTimeout(this.inactivityTimerId);
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setupBeforeUnloadListener();
    });
  },
  beforeRouteLeave (to, from, next) {
    this.stopTimeTracking(false);
    this.removeBeforeUnloadListener();
    next();
  },
};
</script>
