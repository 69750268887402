<template>
  <div class="mx-auto overflow-auto">
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-overlay>
      <div style="min-height: 156px;" v-if="vitals?.length === 0 && Array.isArray(surveyForPatientReport) && surveyForPatientReport?.length <= 0" class="d-flex align-center justify-center" >
        <span  style="border: #e3e3e3 dashed 2px; color:#a3a3a3" class="pa-3 text-subtitle-1">
        {{$t('thisUserHasNoDevices')}}
        </span>
      </div>
      <v-card color="transparent" class="d-flex elevation-0">
        <v-card
            v-for="(item, i) in vitals"
            :key="i"
            hover
            elevation-4
            @click="toggleDrawer(i)"
            :ripple="false"
            class="mx-1 mt-3 mb-4 border-radius7"
        >
          <v-sheet
              color="white"
              elevation="1"
              height="130"
              width="180"
              class="d-flex align-center"
          >
            <v-row class="d-flex align-center" dense>
              <div class="mx-auto pa-2">
                <v-img class="mx-auto" :src="require('@/assets/icons/'+ item.deviceIcon +'black.svg')" :alt="item.deviceName" max-width="17" @click.stop="enterManualMeasurementsDialog(item)"></v-img>
                <div>
                  <span class="color-black font-weight-medium font-size14">{{$i18n.locale === 'en' ? item.deviceName : item.deviceNameEsp}}</span>
                </div>
              </div>
              <v-col cols="12" class="pa-0 ma-0">
                <div v-if="item.measurementsData?.length > 0" style="height: 100%;!important" class="d-flex flex-column align-center">
                  <span class="ma-0 py-0 black--text font-weight-bold">{{ lastMeasurement(item) }}</span>
                  <span class="font-size14 text-decoration">{{ lastMeasuredTime(item) }}</span>
                </div>
                <span v-else class="color-primary font-size14 font-weight-medium">{{$t("no-recorded-data")}}</span>
              </v-col>
            </v-row>
          </v-sheet>
        </v-card>
        <v-card hover @click="openAssessments(index)"  v-for="(survey, index) in surveyForPatientReport" :key="survey.id" style="min-width: 180px; max-width: 180px; height: 130px"
           class="d-flex align-center flex-column mr-2 mt-3 ml-1 overflow-y-auto border-radius7">
          <v-icon class="color-black mt-7">mdi-format-list-checks</v-icon>
          <span class="color-black font-weight-medium font-size14">{{survey.templateName}}</span>
          <span class="font-size14 mt-3">{{ getLocalizedRecurrence(survey.recurrenceRuleOfLastRecurrence) }}</span>
        </v-card>
        <add-manual-measurement-dialog :dialog="dialog" :vitals="selectedVital" />
      </v-card>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import AddManualMeasurementDialog from '@/views/dialogs/AddManualMeasurementDialog';
export default {
  components: {
    AddManualMeasurementDialog,
  },
  data () {
    return {
      isLoading: false,
      doubleClickCounter: 0,
      selectedVitalsDrawer: false,
      selectedDevice: [],
      selectedVital: null,
      vitals: [],
      changeNames: true,
      isDrawerOpen: false,
      dialog: false,
      clickedItem: null,
      drawerContent: null,
      selectedAssessment: null,
      loading: true,
    };
  },

  computed: {
    ...mapState({
      detailedUser: (state) => state.users.detailedUser,
      hubs: (state) => state.nosessionhubs.hubs,
      surveyForPatient: (state) => state.mentalhealth.surveyPatentDetails,
      surveyForPatientReport: (state) => state.mentalhealth.surveyReportForPatient,
    }),
    ...mapGetters({ userSettings: 'authentication/getUserSettings' }),
    assignedMedicalDevices () {
      return this.hubs?.assignedMedicalDevicesList?.map((item) => ({
        deviceDefaultValuesJson: item.deviceDefaultValuesJson,
        deviceDefaultExtremeValuesJson: item.deviceDefaultExtremeValuesJson,
        deviceType: item.deviceType,
        measurementsData: item.measurementsData,
        deviceName: item.deviceName,
        id: item.id,
        isManualMeasurement: item.isManualMeasurement,
        device: item,
        deviceNameEsp: item.deviceNameEsp,
      }));
    },
  },
  methods: {
    getRecurrenceLocalized (reports) {
      if (this.$i18n.locale === 'es') {
        switch (reports) {
          case 'Daily':
            return 'A diario';
          case 'Every 4th Day':
            return 'Cada 4 días';
          case 'Monthly':
            return 'Mensual';
          case 'Every 2nd Day':
            return 'Cada 2 días';
          case 'Every 3rd Day':
            return 'Cada 3er día';
          case 'Once':
            return 'Una vez';
        }
      }
      return reports;
    },
    getLocalizedRecurrence (recurrenceRule) {
      return this.getRecurrenceLocalized(recurrenceRule);
    },
    enterManualMeasurementsDialog (item) {
      this.doubleClickCounter++;
      if (this.doubleClickCounter === 1) {
        this.timer = setTimeout(() => {
          this.doubleClickCounter = 0;
        }, 300);
        return;
      }
      clearTimeout(this.timer);
      this.doubleClickCounter = 0;
      this.selectedDevice = item;
      this.changeNames = false;
      this.selectedVital = item;
      this.dialog = !this.dialog;
    },
    synchronizeDrawers (drawer) {
      this.$store.commit('SET_PATIENT_DETAILS_DRAWER', drawer);
      this.$store.commit('SET_UPDATE_DETAILS_DRAWER', !this.updateDrawer);
    },

    formatDate (submitAtDate) {
      const date = new Date(submitAtDate);
      const options = { month: 'long', day: 'numeric', year: 'numeric' };
      return date.toLocaleDateString('en-US', options);
    },
    openAssessments (index) {
      this.$emit('toggledrawer2', { isOpen: true, selectedSurvey: this.surveyForPatientReport[index] });
      this.clickedItem = index;
      this.drawerContent = null;
      this.selectedAssessment = this.surveyForPatientReport[index];
      this.isDrawerOpen = true;
    },
    async getSurveys () {
      this.isLoading = true;
      const headers = {
        patientId: this.detailedUser.userData.id,
        filterCase: this.$cookies.get('datafilter'),
        limitForOccurrences: 50,
      };
      await this.$store.dispatch('mentalhealth/getSurveyReportForPatient', headers).then(res => {
        this.$store.commit('mentalhealth/GET_SURVEY_FLAG', res.resFlag);
        this.isLoading = false;
      });
    },
    toggleDrawer (index) {
        this.selectedVitalsDrawer = this?.vitals[index];
        this.$emit('toggledrawer', this.selectedVitalsDrawer);
    },
    closeDrawer () {
      this.selectedVitalsDrawer = false;
    },
    async getHubsData (data) {
      this.isLoading = true;
      await this.$store.dispatch('nosessionhubs/getHubsData', data).then(
          this.loading = false,
      );
      this.isLoading = false;
    },
    fixVitalnames () {
      this.vitals = this.assignedMedicalDevices;
      for (const device in this?.vitals) {
        switch (this?.vitals[device]?.deviceType) {
          case 3:
            this.vitals[device].deviceIcon = 'blood_pressure_';
            this.vitals[device].deviceName = 'Blood Pressure';
            break;
          case 6:
            this.vitals[device].deviceIcon = 'glucose_meter_';
            this.vitals[device].deviceName = 'Glucose Meter';
            break;
          case 1:
            this.vitals[device].deviceIcon = 'thermometer_';
            this.vitals[device].deviceName = 'Thermometer';
            break;
          case 2:
            this.vitals[device].deviceIcon = 'oxymeter_';
            this.vitals[device].deviceName = 'Oximeter';
            break;
          case 5:
            this.vitals[device].deviceIcon = 'scale_';
            this.vitals[device].deviceName = 'Weight Scale';
            break;
          case 7:
            this.vitals[device].deviceIcon = 'cholesterol_meter_';
            this.vitals[device].deviceName = 'Cholesterol Meter';
            break;

          default:
            break;
        }
      }
    },

    timeAgo (date) {
      if (!date) {
        return;
      }
      if (typeof date !== 'object') {
        date = new Date(date);
      }

      const seconds = Math.floor((new Date() - date) / 1000);
      let intervalType;

      let interval = Math.floor(seconds / 31536000);
      if (interval >= 1) {
        intervalType = this.$t('year');
      } else {
        interval = Math.floor(seconds / 2592000);
        if (interval >= 1) {
          intervalType = this.$t('month');
        } else {
          interval = Math.floor(seconds / 86400);
          if (interval >= 1) {
            intervalType = this.$t('day');
          } else {
            interval = Math.floor(seconds / 3600);
            if (interval >= 1) {
              intervalType = this.$t('hour');
            } else {
              interval = Math.floor(seconds / 60);
              if (interval >= 1) {
                intervalType = this.$t('minute');
              } else {
                interval = seconds;
                intervalType = this.$t('second');
              }
            }
          }
        }
      }
      if (interval > 1 || interval === 0) {
        intervalType += 's';
      }
      const temp = intervalType + ' ' + this.$t('ago');
      const result = {
        intervalValue: interval,
        intervalType: temp,
      };
      return result;
    },

    lastMeasurement (device) {
      if (device.measurementsData?.length === 0) {
        return;
      }
      const measurements = JSON.parse(device.measurementsData[0].measuredDataJson);

      switch (device.deviceName) {
        case 'thermometer':
          if (this.userSettings.Units.toLowerCase() === 'metric') {
            return measurements?.Temperature_C + ' °C';
          } else if (this.userSettings.Units.toLowerCase() === 'imperial') {
            return measurements.Temperature_F + ' °F';
          }
          break;
        case 'blood-pressure':
          return measurements.Dias !== undefined ? measurements.Sys + '/' + measurements.Dias + ' mmHg' : '';
        case 'oximeter':
          return measurements?.BPM !== undefined ? measurements?.Spo2 + ' SpO2 ' + measurements?.BPM + ' bpm ' + ' Pi ' + (measurements?.PI).toFixed(2) + ' %' : '';
        case 'weight-scale':
          if (this.userSettings.Units.toLowerCase() === 'metric') {
            return measurements.weight_kg + ' kg';
          } else return measurements.weight_lbs + ' lb';
        case 'glucose-meter':
          if (this.userSettings.Concentration.toLowerCase() === 'mmol/l') {
            return measurements.glucose_mmolL + ' mmol/L';
          } else return measurements.glucose_mgL + ' mg/dL';
        case 'cholesterol-meter':
          if (this.userSettings.Concentration.toLowerCase() === 'mmol/l') {
            return measurements.cholesterol_mmolL + ' mmol/L';
          } else return measurements.cholesterol_mgL + ' mg/dL';
      }
    },

    lastMeasuredTime (device) {
      const result = this.timeAgo(device.measurementsData[0]?.measuredAt);
      return result?.intervalValue + ' ' + result?.intervalType;
    },
  },

  async mounted () {
    await this.getSurveys();
    const data = {
      id: this.detailedUser.userData.id,
      filter: this.$cookies.get('datafilter'),
    };
    await this.getHubsData(data);
    this.fixVitalnames();
  },
};
</script>
