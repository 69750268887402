<template>
  <v-card elevation="3" style="border-radius:7px;" :height="pageHeight(40)" class="overflow-hidden" >
    <v-card-title style="border-bottom:1px solid #e1dce8;">
      <v-row dense>
        <v-col class="d-flex align-center justify-start" cols="12">
          <h3 class="mx-2 font-size14" v-if="description !== 'Notifications' && description !== 'Notificaciones'"> {{description}} {{$t('notifications')}} </h3>
          <h3 class="mx-2 font-size14" v-else>{{description}}</h3>
        </v-col>
        <v-col class="d-flex justify-center align-center" cols="4">
          <v-btn-toggle v-model="tab" >
            <v-btn
                width="100%"
                id="custom-btn-all"
                style="height:30px;"
                class="font-size11"
                @click="change('all')">{{$t("all")}}</v-btn>
            <v-btn
                width="100%"
                id="custom-btn-missed"
                class="font-size11"
                style="height:30px;"
                @click="change('abnormal')">{{$t("missed")}}</v-btn>
            <v-btn
                width="100%"
                style="height:30px;"
                class="font-size11"
                id="custom-btn-abnormal"
                @click="change('missed')">{{$t("abnormal")}}</v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card :height="pageHeight(-50)" class="overflow-auto">
      <div v-if="notifications.length === 0">
        <h5 class="font-size12-5 font-weight-bold mt-2" v-if="tab === 0">{{$t('notifications-not-available')}} </h5>
        <h5 class="font-size12-5 font-weight-bold mt-2" v-if="tab === 1">{{$t('notifications-not-available')}}  </h5>
        <h5 class="font-size12-5 font-weight-bold mt-2" v-if="tab === 2">{{$t('notifications-not-available')}}  </h5>
      </div>
      <div
          style="border-bottom: 1px solid #e1dce8; cursor:pointer; font-size:12px;"
          v-for="item in notifications"
          :key="item.NId"
          class="d-flex justify-left pa-2"
      >
        <div class="d-flex align-center">
          <div v-if="item.NotificationTypeId === 5">
            <v-icon
                :color="getIconColor(item.NotificationTypeId)"
                small
                :class="getIconClass(item.NotificationTypeId)"
                class="mx-2"
            />
          </div>
          <div v-else>
            <v-icon
                :color="getIconColor(item.NotificationTypeId)"
                small
                :class="getIconClass(item.NotificationTypeId)"
                class="mx-2"
            />
          </div>
          <div class="text-left font-size10 font-weight-bold" :style="{color: item.NotificationTypeId === 5 ? 'red' : 'inherit'}">
            <span class="text-left">{{ item.NotificationTimestamp | dateformat('DD MMM YYYY', null) }}</span>
          </div>
        </div>
        <div class="pa-0 mx-3 text-left">
          <span class="text-left font-weight-bold"> {{ language === 'MessageToSend' ? item.MessageToSend : item.MessageToSendEsp }}</span>
        </div>
      </div>
    </v-card>
    <AbnormalNotificationDialog v-if="abnormalNotificationDialog" :dialog="abnormalNotificationDialog" :item="notificationToProcess" :type="3" @false="abnormalProcessed" @close="abnormalNotificationDialog = false" />
  </v-card>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import AbnormalNotificationDialog from '../../dialogs/AbnormalNotificationDialog';
export default {
  components: {
    AbnormalNotificationDialog,
  },
  data () {
    return {
      loading: false,
      description: this.$t('notifications'),
      tab: 0,
      items: [],
      selectedNotification: null,
      notificationToProcess: null,
      abnormalNotificationDialog: false,
      searchNotification: null,
    };
  },
  computed: {
    ...mapState({
      notificationsFromRepo: (state) => state.notifications.allNotifications,
      abnormalNotifications: (state) => state.notifications.abnormalMeasurements,
      missed: (state) => state.notifications.missMeasurements,
      mentalHealth: (state) => state.notifications.mentalHealth,
      detailedUser: (state) => state.users.detailedUser,
    }),
    ...mapGetters({ pageHeight: 'pageHeight' }),
    getIconColor () {
      return (notificationTypeId) => {
        switch (notificationTypeId) {
          case 5:
            return 'primary';
          case 3:
            return 'primary';
          case 12:
            return 'primary';
          case 1:
            return 'primary';
          case 4:
            return 'primary';
          default:
            return 'primary';
        }
      };
    },
    getIconClass () {
      return (notificationTypeId) => {
        switch (notificationTypeId) {
          case 5:
            return 'mdi mdi-alert';
          case 3:
            return 'mdi mdi-call-missed';
          case 12:
            return 'mdi mdi-message-alert';
          case 1:
            return 'mdi mdi-message-bulleted';
          case 4:
            return 'mdi mdi-bell-ring';
          case 7:
            return 'mdi mdi-message-alert';
          default:
            return 'mdi mdi-message-alert';
        }
      };
    },

    notifications () {
      if (this.tab === 0) return this.notificationsFromRepo.filter(p => { return p.NotificationDirection === 'INCOMING' && p.NotificationTypeId !== 1; });
      if (this.tab === 2) return this.abnormalNotifications;
      if (this.tab === 1) return this.mentalHealth;
      return this.missed;
    },
    language () {
      return this.$i18n.locale === 'en' ? 'MessageToSend' : 'MessageToSendEsp';
    },
  },
  watch: {
    selectedNotification (val) {
      const item = this.notifications.filter(n => { return n.NotificationId === val; });
      if (item) {
        this.notificationToProcess = item[0];
      }
    },
    notificationToProcess (val) {
      if (val && val.NotificationTypeId === 3) {
        this.abnormalNotificationDialog = true;
      }
    },
  },
  async mounted () {
    await this.initialize();
  },
  methods: {
    async initialize () {
      this.loading = true;
      this.items = this.notificationsFromRepo.filter(p => { return p.NotificationDirection === 'INCOMING' && p.NotificationTypeId !== 1; });
      this.loading = false;
    },
    change (type) {
      this.loading = true;
      switch (type) {
        case 'all':
          this.items = this.notificationsFromRepo.filter(p => { return p.NotificationDirection === 'INCOMING' && p.NotificationTypeId !== 1; });
          this.description = this.$t('notifications');
          break;
        case 'abnormal':
          this.items = this.abnormalNotifications;
          this.description = this.$t('missed');
          break;
        case 'missed':
          this.items = this.missed;
          this.description = this.$t('abnormal');
          break;
        default:
          break;
      }
      this.loading = false;
    },
    abnormalProcessed () {
      this.abnormalNotificationDialog = false;
    },
  },
};
</script>
