<template>
  <div>
    <v-card class="elevation-0 border-radius0" height="150" style="position:sticky; top:0;left:0; z-index:10000;">
      <div class="d-flex pa-5 pb-0">
        <v-icon class="mt-3 mr-4 " style="border:1px solid #ccc; border-radius: 3px;" @click="closeDrawer" color="black">mdi mdi-chevron-left</v-icon>
        <h6 class="mt-4 font-size13 ">{{ selectedSurvey.selectedSurvey.templateName }}</h6>
      </div>
      <v-row  dense class="mx-15 my-3">
        <v-col style="background-color: #E1DCE8;" v-for="(btn, index) in buttons" class="px-1" :key="index">
          <v-btn
              block
              class="font-size11"
              elevation="0"
              :style="{ borderRadius: '0px', width: '100%', fontSize: '13px', height: '15px', backgroundColor: index === selectedTab ? '#E1DCE8' : 'white', color: index === selectedTab ? 'black' : 'black' }"
              @click="selectTab(index)"
          >
            {{ btn }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
    <!-- Add your headers here -->
    <div v-if="selectedTab === 0" >
      <v-expansion-panels hover accordion :height="pageHeight(-250)">
        <v-expansion-panel v-for="(item, index) in recurrencesList" :key="index">
          <v-expansion-panel-header hide-actions>
            <template v-slot:default="{ open }">
              <div>
                <div class="d-flex align-center">
                  <v-icon>
                    <template v-if="open">mdi-chevron-up</template>
                    <template v-else>mdi-chevron-down</template>
                  </v-icon>
                  <div >
                    <h6 class="font-size12 " :style="{ color: item.isCompleted ? 'black' : 'red' }">
                      {{ formatDate(item.submitAtDate) }}
                    </h6>
                </div>
                 <span class="font-size12 ml-auto mr-5 font-weight-bold">{{recurrencesList[0].templateName}}</span>
                  <h6 class="font-size12 ml-auto mr-5" :style="{ color: item.isCompleted ? 'black' : 'red' }">
                    {{ item.isCompleted ? 'Completed' : 'Not Completed' }}
                  </h6>
                </div>
              </div>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-card class="mt-1">
              <v-card elevation="0" v-for="(question, index) in item.surveyQuestionsList" :key="index" class="text-left">
                <div class="d-flex">
                  <v-avatar size="18" color="chips" class="ma-1">
                    <span class="text-white font-size12">{{ index + 1 }}</span>
                  </v-avatar>
                  <div>
                    <span class="font-weight-bold font-size13 ">{{ question.question }}</span>
                  </div>
                </div>
                <div>
                  <div v-for="(answer, index2) in question.offeredAnswersList" :key="index2" class=" font-size12-5 font-weight-medium ml-6">
                    <div v-if="answer.selectedAsAnswer">
                      {{ answer.answer }}
                    </div>
                    <div v-else-if="index2 === question.offeredAnswersList.length - 1 && !question.offeredAnswersList.some(ans => ans.selectedAsAnswer)">
                      <div v-for="(ans, index3) in question.offeredAnswersList" :key="index3">
                        {{ ans.answer }}
                      </div>
                    </div>
                  </div>
                </div>
              </v-card>
            </v-card>

          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <!-- charts sr=taring here   -->
    <div class="mb-8" v-if="selectedTab === 1">
      <AssessmentsGraph30 v-if="graphData !== null && graphData !== undefined && graphData.SurveyCategoryId === 30"
                          ref="assessmentsGraph30"
                          :graphData="graphData"
      >
      </AssessmentsGraph30>
      <AssessmentsGraph31 v-if="graphData !== null && graphData !== undefined && graphData.SurveyCategoryId === 31"
                          ref="assessmentsGraph31"
                          :graphData="graphData"
      >
      </AssessmentsGraph31>
      <AssessmentsGraph32 v-if="graphData !== null && graphData !== undefined && graphData.SurveyCategoryId === 32"
                          ref="assessmentsGraph32"
                          :graphData="graphData"
      >
      </AssessmentsGraph32>
      <AssessmentsGraph27 v-if="graphData !== null && graphData !== undefined && graphData.SurveyCategoryId === 27"
                          ref="assessmentsGraph32"
                          :graphData="graphData"
      >
      </AssessmentsGraph27>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import AssessmentsGraph30 from '@/views/Client/components/AssessmentsGraph30.vue';
import AssessmentsGraph31 from '@/views/Client/components/AssessmentsGraph31.vue';
import AssessmentsGraph32 from '@/views/Client/components/AssessmentsGraph32.vue';
import AssessmentsGraph27 from '@/views/Client/components/AssessmentsGraph27.vue';

export default {
  props: {
    selectedSurvey: Object,
  },
  components: {
    AssessmentsGraph30, AssessmentsGraph31, AssessmentsGraph32, AssessmentsGraph27,
  },
  data () {
    return {
      width: 5,
      radius: 0,
      getCompletedItem: null,
      padding: 8,
      autoLineWidth: false,
      color: '#6600FF',
      buttons: [this.$t('surveyHistory'), this.$t('progressGraphs')],
      selectedTab: 0,
      icon: 'mdi-chevron-right',
      recurrencesList: [],
      graphData: null,
    };
  },
  computed: {
    ...mapState({
      surveyForPatient: (state) => state.mentalhealth.surveyPatentDetails,
    }),
    ...mapGetters({	pageHeight: 'pageHeight' }),
  },
  async created () {
    this.getRecurrences();
    this.generateChartData();
  },
  async mounted () {
  },
  methods: {
    selectTab (index) {
      this.selectedTab = index;
    },
    changeIcon () {
      this.icon = this.icon === 'mdi-chevron-right' ? 'mdi-chevron-down' : 'mdi-chevron-right';
    },
    generateChartData () {
      if (this.selectedSurvey.selectedSurvey === null) return;

      const surveysList = this.selectedSurvey.selectedSurvey.surveysList;

      if (surveysList.length === 0) { return; }

      const firstSurvey = surveysList[0];

      const mentalHealthCategoryId = firstSurvey.mentalHealthCategoryId;

      var question1Text = null;
      var question2Text = null;
      var question3Text = null;

      var question1SeriesData = [];
      var question2SeriesData = [];
      var question3SeriesData = [];

      if (mentalHealthCategoryId === 30) {
        for (let i = 0; i < surveysList.length; i++) {
          const currentSurvey = surveysList[i];

          for (let j = 0; j < currentSurvey.surveyRecurrencesList.length; j++) {
            const currentRecurrence = currentSurvey.surveyRecurrencesList[j];

            // check if the recurrence is completed/answered by patient
            if (currentRecurrence.isCompleted === true) {
              const questionList = currentRecurrence.surveyQuestionsList;

              for (let ii = 0; ii < questionList.length; ii++) {
                const currentQuestion = questionList[ii];

                for (let jj = 0; jj < currentQuestion.offeredAnswersList.length; jj++) {
                  const currentAnswer = currentQuestion.offeredAnswersList[jj];

                  // do the calculation only for selected one as answered
                  if (currentAnswer.selectedAsAnswer === true) {
                    if (currentQuestion.orderNumber === 1) {
                      // handle Q1
                      question1Text = currentQuestion.question;
                      question1SeriesData.push([currentRecurrence.submitAtDate, parseInt(currentAnswer.answer)]);
                    } else if (currentQuestion.orderNumber === 2) {
                      // handle Q2
                      question2Text = currentQuestion.question;
                      if (currentAnswer.answer === 'Yes') {
                        question2SeriesData.push([currentRecurrence.submitAtDate, 1]);
                      } else {
                        question2SeriesData.push([currentRecurrence.submitAtDate, -1]);
                      }
                    } else if (currentQuestion.orderNumber === 3) {
                      // handle Q3
                      question3Text = currentQuestion.question;
                      if (currentAnswer.answer === 'Yes') {
                        question3SeriesData.push([currentRecurrence.submitAtDate, 1]);
                      } else {
                        question3SeriesData.push([currentRecurrence.submitAtDate, -1]);
                      }
                    }
                  }
                }
              }
            }
          }
        }

        this.graphData = {
          SurveyCategoryId: 30,
          Question1: {
            QuestionText: question1Text,
            Series: [{
              name: 'Selected answer',
              data: question1SeriesData,
            }],
            PossibleAnswers: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
          },
          Question2: {
            QuestionText: question2Text,
            Series: [{
              name: 'Selected answer',
              data: question2SeriesData,
            }],
            // eslint-disable-next-line quotes
            PossibleAnswers: ["Yes", "No"],
          },
          Question3: {
            QuestionText: question3Text,
            Series: [{
              name: 'Selected answer',
              data: question3SeriesData,
            }],
            // eslint-disable-next-line quotes
            PossibleAnswers: ["Yes", "No"],
          },
        };
      } else if (mentalHealthCategoryId === 31) {
        for (let i = 0; i < surveysList.length; i++) {
          const currentSurvey = surveysList[i];

          for (let j = 0; j < currentSurvey.surveyRecurrencesList.length; j++) {
            const currentRecurrence = currentSurvey.surveyRecurrencesList[j];

            // check if the recurrence is completed/answered by patient
            if (currentRecurrence.isCompleted === true) {
              const questionList = currentRecurrence.surveyQuestionsList;

              for (let ii = 0; ii < questionList.length; ii++) {
                const currentQuestion = questionList[ii];

                for (let jj = 0; jj < currentQuestion.offeredAnswersList.length; jj++) {
                  const currentAnswer = currentQuestion.offeredAnswersList[jj];

                  // do the calculation only for selected one as answered
                  if (currentAnswer.selectedAsAnswer === true) {
                    if (currentQuestion.orderNumber === 1) {
                      // handle Q1
                      question1Text = currentQuestion.question;
                      if (currentAnswer.answer === 'Yes') {
                        question1SeriesData.push([currentRecurrence.submitAtDate, 1]);
                      } else {
                        question1SeriesData.push([currentRecurrence.submitAtDate, -1]);
                      }
                    }
                  }
                }
              }
            }
          }
        }

        this.graphData = {
          SurveyCategoryId: 31,
          Question1: {
            QuestionText: question1Text,
            Series: [{
              name: 'Selected answer',
              data: question1SeriesData,
            }],
            // eslint-disable-next-line quotes
            PossibleAnswers: ["Yes", "No"],
            xAxisCategories: [],
          },
        };
      } else if (mentalHealthCategoryId === 32) {
        for (let i = 0; i < surveysList.length; i++) {
          const currentSurvey = surveysList[i];

          for (let j = 0; j < currentSurvey.surveyRecurrencesList.length; j++) {
            const currentRecurrence = currentSurvey.surveyRecurrencesList[j];

            // check if the recurrence is completed/answered by patient
            if (currentRecurrence.isCompleted === true) {
              const questionList = currentRecurrence.surveyQuestionsList;

              for (let ii = 0; ii < questionList.length; ii++) {
                const currentQuestion = questionList[ii];

                for (let jj = 0; jj < currentQuestion.offeredAnswersList.length; jj++) {
                  const currentAnswer = currentQuestion.offeredAnswersList[jj];

                  // do the calculation only for selected one as answered
                  if (currentAnswer.selectedAsAnswer === true) {
                    if (currentQuestion.orderNumber === 1) {
                      // handle Q1
                      question1Text = currentQuestion.question;
                      if (currentAnswer.answer === 'Yes') {
                        question1SeriesData.push([currentRecurrence.submitAtDate, 1]);
                      } else {
                        question1SeriesData.push([currentRecurrence.submitAtDate, -1]);
                      }
                    }
                  }
                }
              }
            }
          }
        }

        this.graphData = {
          SurveyCategoryId: 31,
          Question1: {
            QuestionText: question1Text,
            Series: [{
              name: 'Selected answer',
              data: question1SeriesData,
            }],
            // eslint-disable-next-line quotes
            PossibleAnswers: ["Yes", "No"],
            xAxisCategories: [],
          },
        };
      } else if (mentalHealthCategoryId === 27) {
        question1Text = this.selectedSurvey.selectedSurvey.templateName; // 'PHQ-9';
        question2Text = this.selectedSurvey.selectedSurvey.templateDescription; // 'Over the last 2 weeks, how often have you been bothered by any of the following problems?';

        for (let i = 0; i < surveysList.length; i++) {
          const currentSurvey = surveysList[i];

          for (let j = 0; j < currentSurvey.surveyRecurrencesList.length; j++) {
            const currentRecurrence = currentSurvey.surveyRecurrencesList[j];

            // check if the recurrence is completed/answered by patient
            if (currentRecurrence.isCompleted === true) {
              question1SeriesData.push([currentRecurrence.submitAtDate, parseInt(currentRecurrence.totalScorePoints)]);
            }
          }
        }

        this.graphData = {
          SurveyCategoryId: 27,
          Question1: {
            QuestionText: question1Text,
            Series: [{
              name: 'Score',
              data: question1SeriesData,
            }],
            PossibleAnswers: Array.from({ length: 28 }, (_, i) => i), // array of 0 to 54
          },
        };
      }
    },
    getRecurrences () {
      for (let i = 0; i < this.selectedSurvey.selectedSurvey.surveysList.length; i++) {
        var currentSurvey = this.selectedSurvey.selectedSurvey.surveysList[i];

        // get the recurrences for this survey
        for (let i = 0; i < currentSurvey.surveyRecurrencesList.length; i++) {
          var currentRecurrence = currentSurvey.surveyRecurrencesList[i];
          this.recurrencesList.push(currentRecurrence);
        }
      }
    },
    closeDrawer () {
      this.$emit('close', { isOpen: false, selectedSurvey: this.selectedSurvey });
    },
    formatDate (timestamp) {
      // Convert input string to a Date object
      var dateObject = new Date(timestamp);

      // Format the date as "MMM, dd, yyyy"
      var formattedDate = dateObject.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      });
      return formattedDate;
    },
  },
};
</script>

<style>
.not-completed {
  color: red;
}
#chartContainer {
  border: 1px solid #ccc;
}
#chart-title {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
#chart-container {
  position: relative;
}
.v-expansion-panel-header{
  min-height: 10px !important;
  padding:5px !important;
}
</style>
