<template>
    <div class="d-flex align-end justify-center mr-3" ref="graphContainer" id="graphContainer" style="height: 100%;" >
        <v-container v-if="graphData !== null && graphData?.SurveyCategoryId == 30">
            <v-row>
                <v-col cols="12">
                    <apexchart type="line" height="400" :options="chartOptions1" :series="graphData.Question1.Series"></apexchart>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <apexchart type="bar" height="300" :options="chartOptions2" :series="graphData.Question2.Series"></apexchart>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <apexchart type="bar" height="300" :options="chartOptions3" :series="graphData.Question3.Series"></apexchart>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
// https://apexcharts.com/vue-chart-demos/mixed-charts/line-column-area/
import Graph from 'vue-apexcharts';
// import convert from 'convert-units';

export default {
    props: {
        graphData: null,
    },
    components: {
        apexchart: Graph,
    },
    data: function () {
        return {
            series: [{
              name: 'Desktop',
              data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
            }],
            chartOptions1: {
                chart: {
                    height: 200,
                    type: 'line',
                    toolbar: {
                        show: false,
                    },
                },
                dataLabels: {
                    enabled: true,
                },
                stroke: {
                    curve: 'straight',
                },
                title: {
                    text: this.graphData != null ? this.graphData.Question1.QuestionText : 'Question',
                    align: 'left',
                },
                xaxis: {
					type: 'datetime',
					labels: {
						show: true,
						style: {
							// fontSize: '0px',
						},
					},
					axisTicks: {
						show: true,
					},
					tooltip: {
						enabled: true,
					},
                    // categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
                    // categories: ['2024-01-20', '2024-01-25', '2024-01-28'],
				},
                yaxis: {
                    title: {
                        text: 'Possible answers',
                    },
                    min: this.graphData != null ? Math.min(...this.graphData.Question1.PossibleAnswers) : 0, // caluculate min value from array of possible answers
                    max: this.graphData != null ? Math.max(...this.graphData.Question1.PossibleAnswers) : 10, // caluculate max value from array of possible answers
                    stepSize: 1,
                    // decimalsInFloat: 0,
                    forceNiceScale: true,
				},
            },
            chartOptions2: {
                chart: {
                    height: 350,
                    type: 'bar',
                    stacked: true,
                    toolbar: {
                        show: false,
                    },
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        colors: {
                            ranges: [
                                {
                                    from: -Infinity,
                                    to: 0,
                                    color: '#FF0000', // Red for negative values
                                },
                                {
                                    from: 0,
                                    to: Infinity,
                                    // color: '#0000FF', // Green for positive values
                                },
                            ],
                        },
                    },
                },
                dataLabels: {
                    enabled: true,
                    formatter: function (val, opt) {
                        if (val === 1) {
                            return 'Yes';
                        } else if (val === -1) {
                            return 'No';
                        } else {
                            return 'x';
                        }
                    },
                },
                stroke: {
                    curve: 'stepline',
                },
                title: {
                    text: this.graphData != null ? this.graphData.Question2.QuestionText : 'Question',
                    align: 'left',
                },
                grid: {
                    // row: {
                    //     colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                    //     opacity: 0.5,
                    // },
                },
                xaxis: {
					type: 'datetime',
					labels: {
						show: true,
						style: {
							// fontSize: '0px',
						},
					},
					axisTicks: {
						show: true,
					},
					tooltip: {
						enabled: true,
					},
				},
                yaxis: {
                    title: {
                        // text: 'Possible answers',
                    },
					min: -1,
					max: 1,
                    decimalsInFloat: 0,
                    labels: {
                        show: false,
                        formatter: function (val, index) {
                            return val.toFixed(0);
                        },
                    },
				},
            },
            chartOptions3: {
                chart: {
                    height: 350,
                    type: 'bar',
                    toolbar: {
                        show: false,
                    },
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        colors: {
                            ranges: [
                                {
                                    from: -Infinity,
                                    to: 0,
                                    color: '#FF0000', // Red for negative values
                                },
                                {
                                    from: 0,
                                    to: Infinity,
                                    // color: '#0000FF', // Green for positive values
                                },
                            ],
                        },
                    },
                },
                dataLabels: {
                    enabled: true,
                    formatter: function (val, opt) {
                        if (val === 1) {
                            return 'Yes';
                        } else if (val === -1) {
                            return 'No';
                        } else {
                            return 'x';
                        }
                    },
                },
                stroke: {
                    curve: 'stepline',
                },
                title: {
                    text: this.graphData != null ? this.graphData.Question3.QuestionText : 'Question',
                    align: 'left',
                },
                grid: {
                    // row: {
                    //     colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                    //     opacity: 0.5,
                    // },
                },
                xaxis: {
					type: 'datetime',
					labels: {
						show: true,
						style: {
							// fontSize: '0px',
						},
					},
					axisTicks: {
						show: true,
					},
					tooltip: {
						enabled: true,
					},
                    // categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
                    categories: ['2024-01-20', '2024-01-25', '2024-01-28'],
				},
                yaxis: {
                    title: {
                        // text: 'Possible answers',
                    },
					min: -1,
					max: 1,
                    // tickAmount: 1,
                    stepSize: 1,
                    decimalsInFloat: 0,
					// autoScaleYaxis: true,
                    labels: {
                        show: false,
                        formatter: function (val, index) {
                            return val.toFixed(0);
                        },
                    },
				},
            },
        };
    },
    computed: {

    },
    async created () {
    },
    async mounted () {
    },
    methods: {

    },
};

</script>
