var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.resize),expression:"resize"}],ref:"chatContainer",staticClass:"overflow-hidden elevation-3",staticStyle:{"border-radius":"7px"}},[_c('vue-advanced-chat',{ref:"vac",staticStyle:{"text-align":"left"},attrs:{"height":_vm.pageHeight(40),"current-user-id":_vm.currentUserId,"rooms":JSON.stringify(_vm.room),"rooms-loaded":_vm.roomsLoaded,"messages":JSON.stringify(_vm.messagesToDisplay),"messages-loaded":_vm.messagesLoaded,"message-actions":_vm.messageActions,"single-room":true,"show-audio":"false","show-files":"false","accepted-files":"image/jpeg","show-emojis":"false","styles":JSON.stringify(_vm.styles),"emojis-suggestion-enabled":false,"text-messages":JSON.stringify(
				{
					ROOMS_EMPTY: _vm.$t('ROOMS_EMPTY'),
					ROOM_EMPTY: _vm.$t('ROOM_EMPTY'),
					NEW_MESSAGES: _vm.$t('NEW_MESSAGES'),
					MESSAGE_DELETED: _vm.$t('MESSAGE_DELETED'),
					MESSAGES_EMPTY: _vm.$t('MESSAGES_EMPTY'),
					CONVERSATION_STARTED: _vm.$t('CONVERSATION_STARTED'),
					TYPE_MESSAGE: _vm.$t('TYPE_MESSAGE'),
					SEARCH: _vm.$t('SEARCH'),
					IS_ONLINE: _vm.$t('IS_ONLINE'),
					LAST_SEEN: _vm.$t('LAST_SEEN'),
					IS_TYPING: _vm.$t('IS_TYPING'),
					CANCEL_SELECT_MESSAGE: _vm.$t('CANCEL_SELECT_MESSAGE')
				}
			),"show-footer":true,"show-reaction-emojis":"false"},on:{"send-message":_vm.sendMessage,"open-file":function($event){return _vm.downloadFile($event.detail[0])}}},[_c('div',{staticClass:"pa-2",staticStyle:{"width":"100% !important"},attrs:{"slot":"room-header"},slot:"room-header"},[_c('div',{staticClass:"d-flex justify-space-between",staticStyle:{"width":"100% !important","display":"flex"}},[_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('h3',{staticClass:"mx-2 font-size14",staticStyle:{"font-weight":"bold","font-size":"18px"}},[_vm._v(_vm._s(_vm.$t("messages")))])]),_vm._v(" "),_c('div',{staticStyle:{"display":"flex","justify-content":"flex-end","align-items":"center"}},[(!_vm.video)?_c('v-icon',{staticClass:"mr-3 pa-2",staticStyle:{"border":"1px solid #ccc","border-radius":"5px"},attrs:{"disabled":_vm.detailedUser.userData.status === 99,"small":"","color":"main_black"},on:{"click":function($event){$event.stopPropagation();return _vm.initiateCall(false)}}},[_vm._v("mdi-phone")]):_vm._e(),_vm._v(" "),(!_vm.video)?_c('v-icon',{staticClass:"pa-2",staticStyle:{"border":"1px solid #ccc","border-radius":"5px"},attrs:{"disabled":_vm.detailedUser.userData.status === 99,"small":"","color":"main_black"},on:{"click":function($event){$event.stopPropagation();return _vm.initiateCall(true)}}},[_vm._v("mdi-video")]):_vm._e()],1)])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }