<template>
  <v-dialog persistent v-model="dialog" max-width="420px"  transition="dialog-top-transition">
    <v-card class="pa-7">
      <v-card-title class="d-flex justify-center align-center">
        <v-row  dense>
          <v-col class="py-0 mb-1" cols="12">
                <span class="icon-background-alert">
                  <v-icon dense color="main_red" size="30px" class="my-0">mdi-alert-outline</v-icon>
                </span>
          </v-col>
          <v-col cols="12">
            <span>{{$t('confirm-deceased-status-change')}}</span>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="text-center">{{$t('confirm-deceased-status-change-description')}}</v-card-text>
      <v-card-actions class="mb-2">
        <v-row dense>
          <v-col cols="12">
            <v-btn block depressed color="main_red" class="text-color-white" @click="setStatusDeceased">{{$t('confirm-deceased-status')}}</v-btn>
          </v-col>
          <v-col cols="12">
            <v-btn outlined depressed color="main_black" block @click="closeDialog">{{ $t("cancel") }}</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
<!--      <v-btn @click="makePatientActive">Make him alive</v-btn>-->
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['dialog', 'detailedUser'],
  data () {
    return {
      localDialog: this.dialog,
    };
  },
  methods: {
    makePatientActive () {
      const requestData = {
        userId: this.detailedUser.userData.id,
        statusValueToSet: 1,
      };
      this.$store.dispatch('users/setUserStatus', requestData);
    },
    async setStatusDeceased () {
      const requestData = {
        userId: this.detailedUser.userData.id,
        statusValueToSet: 99,
      };
      await this.$store.dispatch('users/setUserStatus', requestData);
      await this.$store.dispatch('users/getUserById', this.detailedUser.userData.id);
      this.closeDialog();
      // location.reload();
      // this.$router.go(-1);
      },
    closeDialog () {
      this.$emit('close-dialog'); // emit event to update par
    },
  },
};
</script>

<style >

</style>
