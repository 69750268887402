import store from '../data/store';

export async function permissions () {
	const Vue = {};
	await navigator.mediaDevices
		.getUserMedia({ audio: true })
		.then(() => {
			Vue.noDevices = false;
		})
		.catch(() => {
			Vue.noDevices = true;
			store.commit('alerts/add', {
				id: 'checkDevices',
				type: 'error',
				color: 'main_red',
				message: 'Camera/Microphone not found',
			});
		});
	return Vue.noDevices;
}
