<template>
  <v-dialog
    v-model="dialog"
    width="1200"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    persistent
    style="background-color: blue; border-radius: 30px !important"
  >
    <v-card flat>
		<v-card-title class="secondary py-1" style="height: 40px">
			<span class="dialog-title"
			>{{ $t("medical") }}
			{{ $t("note") }}</span
			>
			<v-spacer />
			<v-icon small color="primary" @click="$emit('close')"
			>mdi-window-close</v-icon
			>
		</v-card-title>
<!--		<div class="pa-8">-->
			<template>
				<vue-editor
					style="height: 500px"
					v-model="medicalNote"
					:editorToolbar="customToolbar"
					:editorOptions="editorOptions"
					>
				</vue-editor>
			</template>
<!--    <v-textarea v-model="medicalNote" :disabled="checkedNote" label="Medical Note" outlined dense></v-textarea>-->
<!--    </div>-->
		<v-card-actions class="mx-4 py-4">
			<v-checkbox v-if="type !== 1" v-model="checkedNote" class="mt-3 ml-0" label="No need to write a note!"></v-checkbox>
			<v-spacer />
			<v-btn class="table-cancel-button" @click="$emit('close')" style="padding-left: 12px !important">
				<span color="black--text">{{ $t("cancel") }}</span>
			</v-btn>
			<v-btn
        class="save-button-style"
				:loading="loading"
				:disabled="loading || valid"
				@click="loader = 'loading'"
			>
				<span>{{ $t("save") }}</span>
			</v-btn>
			</v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { VueEditor } from 'vue2-editor';
export default {
	components: { VueEditor },
	props: ['dialog', 'item', 'type', 'source', 'patientId'],
	data () {
		return {
			loader: null,
			loading: false,
			medicalNote: '',
			checkedNote: false,
			content: '<h1>Some initial content</h1>',
			customToolbar: [
				// [{ header: [false, 1, 2, 3, 4] }],
				// ['bold', 'italic', 'underline'],
				// [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
				// [{ align: '' },
				// 	{ align: 'center' },
				// 	{ align: 'justify' }],
				// ['image'],
				// ['clean'],
			],
			editorOptions: {
				modules: {
					toolbar: false,
				},
			},
		};
	},
	computed: {
		valid () {
			return !this.medicalNote;
		},
	},
	watch: {
		async loader () {
			if (this.loader !== null) {
				const l = this.loader;
				this[l] = !this[l];

				await this.sendNote()
					.then(() => {
						this[l] = false;
					})
					.catch(() => {
						this[l] = false;
					});

				this.loader = null;
			}
		},
	},
	methods: {
		async addNote () {
			const body = {
				appointmentId: null,
				noteDescription: this.medicalNote,
				patientId: this.patientId,
				noteType: this.type,
			};
			await this.$store.dispatch('appointments/addAppointmentNote', body);
		},
		async sendNote () {
			// if (!this.checkedNote) {
			await this.addNote();
			// await this.sendMessage();
			this.$emit('close');
			// } else {
			// 	await this.markAsRead();
			// }
		},
		async markAsRead () {
			if (this.source === 'note') {
				this.$emit('false');
				return;
			}
			if (!this.item.IsRead) {
				await this.$store.dispatch(
					'notifications/markAsRead',
					this.item.NotificationId,
				);
				this.$emit('false');
			}
			this.$emit('close');
		},
		async sendMessage () {
			const body = {
				userId: this.item.FromUserId,
				messageToSend: '[Medical Note]: ' + this.medicalNote,
				notificationTypeId: 1,
			};
			await this.$store.dispatch('notifications/changeMessageStatus', body);
			await this.markAsRead();

			// await this.$store.dispatch('notifications/sendMessage', body).then(async () => {
			// 	await this.markAsRead();
			// });
		},
	},
};
</script>
