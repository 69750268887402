<template>
  <v-dialog
      v-model="localDialog"
      class="dialog-style"
  >
    <v-card >
      <v-card-title class="primary2 py-1 mb-2 " style="height: 40px; position: sticky; top: 0; z-index: 10">
        <v-row  class="d-flex justify-end align-center">
          <span class="dialog-title white-color" >{{ $t('last-month-measurements') }}</span>
          <v-spacer />
<!--          <v-chip-->
<!--              @click="downloadPDF(detailedUser.userData.firstName, detailedUser.userData.lastName)"-->
<!--              pill-->
<!--              small-->
<!--              label-->
<!--              medium-->
<!--              color="primary"-->
<!--              class="mx-1 text&#45;&#45;black"-->
<!--              style="float:right"-->
<!--          >-->
<!--            {{ $t("download-medical-reports") }}-->
<!--          </v-chip>-->
          <v-icon class="mr-5 white-icon"
          @click="downloadPDF(detailedUser.userData.firstName, detailedUser.userData.lastName)"
          >mdi-cloud-download</v-icon>
          <v-icon  color="white" @click="closeDialog">mdi-window-close</v-icon>
        </v-row>
      </v-card-title>
      <v-card-text>
        <div id="contentToPrint" style="font-family: Arial;">
          <v-row style="padding-bottom: 24px; margin-bottom: 0px; margin-top: 24px">
            <v-col  style="margin-left: 16px; margin-right: 16px; padding-right: 64px">
              <v-row><span>{{detailedUser.userData?.firstName + ' ' + detailedUser.userData?.lastName}}</span></v-row>
              <v-row><span>{{detailedUser.userData?.address}}</span></v-row>
              <v-row><span>{{phone(this.detailedUser.userData?.phone)}}</span></v-row>
              <v-row><span>{{detailedUser.userData?.email}}</span></v-row>
              <v-row><span>{{detailedUser?.userData?.patientMetadata?.medicalRegistrationNumber ? detailedUser?.userData?.patientMetadata?.medicalRegistrationNumber : ''}}</span></v-row>
            </v-col>
            <v-col style="text-align: left ;margin-left: 4px; margin-right: 4px;">
              <v-row >{{detailedUser.medicalTeamData[0]?.hospitalName}}</v-row>
              <!-- <v-row style="text-right: right"><span>{{hospitals[0]?.address}}</span></v-row> -->
              <v-row >{{ hospitals[0]?.address }}</v-row>
              <v-row >{{hospitals[0]?.contactPhone}}</v-row>
              <v-row >{{hospitals[0]?.contactEmail}}</v-row>
            </v-col>
          </v-row>
          <v-app id="inspire">
            <v-data-table
                :headers="header"
                :items="groups"
                dense
                style="font-family: Arial;"
                :single-expand="singleExpand"
                :no-data-text="$t('no-data-available')"
                hide-default-header
                item-key="name"
                :items-per-page="-1"
                :expanded.sync="groups"
                hide-default-footer
                class="elevation-1"
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td style="text-align: start; background-color: lightgray" >
                    <span class="font-weight-black"><b>{{item.date}}</b></span>
                  </td >
                </tr>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <template>
                    <v-row >
                      <v-col v-if="patientHasAssignedThisDeviceTypeId(3)">
                        <v-data-table
                            dense
                            :headers="headerBlodpressure"
                            :items="item.elements.BloodPressureMeter"
                            :no-data-text="$t('no-data-available')"
                            hide-default-footer
                            :items-per-page="-1"
                            class="elevation-0"
                        >
                          <template v-slot:top>
                            <span class="font-weight-bold" style="font-size: small">{{ $t("blood-pressure") }}</span>
                          </template>
                          <template v-slot:item="{ item }">
                            <tr v-if="item.deviceType === 3">
                              <td style="text-align: center">
                                {{item.time}}
                              </td >
                              <td style="text-align: center">
                                {{item.measuredDataJson.Sys}} / {{item.measuredDataJson.Dias}}
                              </td >
                              <td style="text-align: center">
                                {{item.measuredDataJson.Pul}}
                              </td >
                            </tr>
                          </template>
                        </v-data-table>
                      </v-col>
                      <v-col v-if="patientHasAssignedThisDeviceTypeId(1)">
                        <v-data-table
                            dense
                            :headers="headerThermometer"
                            :items="item.elements.Thermometer"
                            :no-data-text="$t('no-data-available')"
                            hide-default-footer
                            :items-per-page="-1"
                            class="elevation-0"
                        >
                          <template v-slot:top>
                            <span class="font-weight-bold" style="font-size: small">{{ $t("temperature") }}</span>
                          </template>
                          <template v-slot:item="{ item }">
                            <tr v-if="item.deviceType === 1">
                              <td style="text-align: center">
                                {{item.time}}
                              </td >
                              <td style="text-align: center">
                                {{Number.parseFloat(item.measuredDataJson.Temperature_C).toFixed(1)}}
                              </td >
                              <td style="text-align: center">
                                {{Number.parseFloat(item.measuredDataJson.Temperature_F).toFixed(1)}}
                              </td >
                            </tr>
                          </template>
                        </v-data-table>
                      </v-col>
                      <v-col v-if="patientHasAssignedThisDeviceTypeId(2)">
                        <v-data-table
                            dense
                            :headers="headerOximeter"
                            :items="item.elements.Oximeter"
                            :no-data-text="$t('no-data-available')"
                            hide-default-footer
                            :items-per-page="-1"
                            class="elevation-0"
                        >
                          <template v-slot:top>
                            <span class="font-weight-bold" style="font-size: small">{{ $t("oximeter") }}</span>
                          </template>
                          <template v-slot:item="{ item }">
                            <tr v-if="item.deviceType === 2">
                              <td style="text-align: center">
                                {{item.time}}
                              </td >
                              <td style="text-align: center">
                                {{Number.parseFloat(item.measuredDataJson.Spo2).toFixed(0)}}
                              </td >
                              <td style="text-align: center">
                                {{Number.parseFloat(item.measuredDataJson.BPM).toFixed(0)}}
                              </td >
                              <td style="text-align: center">
                                {{Number.parseFloat(item.measuredDataJson.PI).toFixed(1)}}
                              </td >
                            </tr>
                          </template>
                        </v-data-table>
                      </v-col>
                      <v-col v-if="patientHasAssignedThisDeviceTypeId(5)">
                        <v-data-table
                            dense
                            :items="item.elements.Weightscale"
                            :no-data-text="$t('no-data-available')"
                            :headers="headerWheight"
                            hide-default-footer
                            :items-per-page="-1"
                            class="elevation-0"
                        >
                          <template v-slot:top>
                            <span class="font-weight-bold" style="font-size: small">{{ $t("weight") }}</span>
                          </template>
                          <template v-slot:item="{ item }">
                            <tr v-if="item.deviceType === 5">
                              <td style="text-align: center">
                                {{item.time}}
                              </td >
                              <td style="text-align: center">
                                {{Number.parseFloat(item.measuredDataJson.weight_kg).toFixed(1)}}
                              </td >
                              <td style="text-align: center">
                                {{Number.parseFloat(item.measuredDataJson.weight_lbs).toFixed(1)}}
                              </td >
                            </tr>
                          </template>
                        </v-data-table>
                      </v-col>
                      <!-- <v-col v-if="patientHasAssignedThisDeviceTypeId(7)">
                        <v-data-table
                            dense
                            :items="item.elements.CholesterolGlucosemeter"
							:no-data-text="$t('no-data-available')"
                            :headers="headerCholesterol"
                            hide-default-footer
                            :items-per-page="-1"
                            class="elevation-0"
                        >
                          <template v-slot:top>
                            <span class="font-weight-bold" style="font-size: small">{{ $t("cholesterol") }}</span>
                          </template>
                          <template v-slot:item="{ item }">
                            <tr v-if="item.deviceType === 7">
                              <td style="text-align: center">
                                {{item.time}}
                              </td >
                              <td style="text-align: center">
                                {{Number.parseFloat(item.measuredDataJson.cholesterol_mgL).toFixed(0)}}
                              </td >
                              <td style="text-align: center">
                                {{Number.parseFloat(item.measuredDataJson.cholesterol_mmolL).toFixed(1)}}
                              </td >
                            </tr>
                          </template>
                        </v-data-table>
                      </v-col> -->
                      <v-col v-if="patientHasAssignedThisDeviceTypeId(6)">
                        <v-data-table
                            dense
                            :items="item.elements.Glucosemeter"
                            :no-data-text="$t('no-data-available')"
                            :headers="headerGlucosemeter"
                            hide-default-footer
                            :items-per-page="-1"
                            class="elevation-0"
                        >
                          <template v-slot:top>
                            <span class="font-weight-bold" style="font-size: small">{{ $t("glucose") }}</span>
                          </template>
                          <template v-slot:item="{ item }">
                            <tr v-if="item.deviceType === 6">
                              <td style="text-align: center">
                                {{item.time}}
                              </td >
                              <td style="text-align: center">
                                {{ Number.parseFloat(item.measuredDataJson.glucose_mgL).toFixed(0)}}
                              </td >
                              <td style="text-align: center">
                                {{Number.parseFloat(item.measuredDataJson.glucose_mmolL).toFixed(1)}}
                              </td >
                              <td style="text-align: center">
                                {{item.measuredDataJson.isMeasuredBeforeMeal ?  $t("before") : $t("after") }}
                              </td >
                            </tr>
                          </template>
                        </v-data-table>
                      </v-col>
                    </v-row>
                  </template>
                </td>
              </template>
            </v-data-table>
          </v-app>
        </div>
      </v-card-text>
    </v-card>
    <spinner class="justify-center" v-if="spinner"></spinner>
  </v-dialog>
</template>

<script>
// var convert = require('convert-units');

import jsPDF from 'jspdf';
import { formatterFilter } from '@/utils/luxon-formater';
import { AsYouType, isValidPhoneNumber } from 'libphonenumber-js';
import Spinner from '@/views/Spinner/LoadingSpinner';
import { mapState } from 'vuex';

export default {
  components: {
    Spinner,
  },
  props: ['dialog', 'assignedMedicalDevices', 'detailedUser'],
  data () {
    return {
      localDialog: this.dialog,
      hospitals: [],
      expanded: [],
      dateData: [],
      spinner: false,
      singleExpand: false,
      groups: [],
      groupByDateAndMesurments: [],
      allDates: [],
      headrByDate: [
        {
          text: this.$t('time'),
          align: 'center',
          sortable: false,
          value: 'time',
        },
        {
          text: this.$t('date'),
          align: 'center',
          sortable: false,
          value: 'date',
        },
      ],
      header: [
        {
          text: 'Date',
          align: 'center',
          sortable: false,
          value: 'date',
        },
      ],
      headerBlodpressure: [
        {
          text: this.$t('time'),
          align: 'center',
          sortable: false,
          value: 'time',
        },
        {
          text: 'mmHg',
          align: 'center',
          sortable: false,
          value: 'date',
        },
        {
          text: 'BPM',
          align: 'center',
          sortable: false,
          value: 'date',
        },
      ],
      headerThermometer: [
        {
          text: this.$t('time'),
          align: 'center',
          sortable: false,
          value: 'date',
        },
        {
          text: 'C',
          align: 'center',
          sortable: false,
          value: 'temperature',
        },
        {
          text: 'F',
          align: 'center',
          sortable: false,
          value: 'date',
        },
      ],
      headerGlucosemeter: [
        {
          text: this.$t('time'),
          align: 'center',
          sortable: false,
          value: 'date',
        },
        {
          text: 'mg/dl',
          align: 'center',
          sortable: false,
          value: 'date',
        },
        {
          text: 'mmol/l',
          align: 'center',
          sortable: false,
          value: 'date',
        },
        {
          text: this.$t('glucose-value-befor-meal'),
          align: 'center',
          sortable: false,
          value: 'date',
        },
      ],
      headerOximeter: [
        {
          text: this.$t('time'),
          align: 'center',
          sortable: false,
          value: 'date',
        },
        {
          text: 'SpO2',
          align: 'center',
          sortable: false,
          value: 'date',
        },
        {
          text: 'BPM',
          align: 'center',
          sortable: false,
          value: 'date',
        },
        {
          text: this.$t('pulse-index'),
          align: 'center',
          sortable: false,
          value: 'date',
        },
      ],
      // headerCholesterol: [
      // 	{
      // 		text: this.$t('time'),
      // 		align: 'center',
      // 		sortable: false,
      // 		value: 'date',
      // 	},
      // 	{
      // 		text: 'mg/dl',
      // 		align: 'center',
      // 		sortable: false,
      // 		value: 'date',
      // 	}, {
      // 		text: 'mmol/l',
      // 		align: 'center',
      // 		sortable: false,
      // 		value: 'date',
      // 	},

      // ],
      headerWheight: [
        {
          text: this.$t('time'),
          align: 'center',
          sortable: false,
          value: 'date',
        },
        {
          text: 'Kg',
          align: 'center',
          sortable: false,
          value: 'date',
        },
        {
          text: 'lbs',
          align: 'center',
          sortable: false,
          value: 'date',
        },
      ],
      timeLocalization: this.$i18n.locale,
    };
  },
  computed: {
    ...mapState({
      hospitalData: (state) => state.hospitals.hospitals,
    }),
  },
  async mounted () {
    this.getHospitals();
    this.allDatesMedicalReports();
  },
  methods: {
    closeDialog () {
      this.localDialog = !this.localDialog;
      this.$emit('update:dialog', this.localDialog); // emit event to update par
    },
    async getHospitals () {
      await this.$store.dispatch('hospitals/getHospitals').then(() => {
        if (this.hospitalData !== null) {
          this.hospitals = this.hospitalData;
        } else this.hospitals = [];
      });
    },
    patientHasAssignedThisDeviceTypeId (type) {
      var isFound = this.assignedMedicalDevices.some(element => {
        if (element.deviceType === type) { return true; }
        return false;
      });
      return isFound;
    },
    phone (val) {
      if (val !== undefined) {
        if (!isValidPhoneNumber(val)) {
          this.phoneErrors = true;
        } else {
          this.phoneErrors = false;
        }
        return new AsYouType().input(val);
      }
    },
    allDatesMedicalReports () {
      this.assignedMedicalDevices.forEach(element => {
        element.measurementsData.forEach(item => {
          this.dateData.push({
            devicename: element.deviceName,
            deviceNameEsp: element.deviceNameEsp,
            measuredAt: item.measuredAt,
            date: formatterFilter(item.measuredAtUtc, 'date_med', this.timeLocalization),
            time: formatterFilter(item.measuredAtUtc, 'time_am_pm', this.timeLocalization),
            referenceData: JSON.parse(item.referenceData),
            deviceType: element.deviceType,
            measuredDataJson: JSON.parse(item.measuredDataJson),
          });
          this.allDates.push(formatterFilter(item.measuredAt, 'date_med', this.timeLocalization));
        });
      });
      this.groups = this.dateData.reduce((acc, curr) => {
        const date = curr.date;
        const devicename = curr.devicename.split(/[ /]/).join('');
        // const devicename = curr.devicename.split(/[ /]/i).join('');

        let group = acc.find(group => group.date === date);

        if (!group) {
          group = { date: date, elements: {} };
          acc.push(group);
        }

        if (!group.elements[devicename]) {
          group.elements[devicename] = [curr];
        } else {
          group.elements[devicename].push(curr);
        }

        return acc;
      }, []);
    },
    downloadPDF (firstName, lastName) {
      // TODO implement the loader when downloading
      this.spinner = false;
      this.pdfColor = '#FFFFFF';
      this.showBorder = true;
      const fileName = 'Patient records ' + firstName + ' ' + lastName + '.pdf';

      // eslint-disable-next-line new-cap
      var doc = new jsPDF('landscape', 'mm', 'a4', true);
      var elementHTML = document.querySelector('#contentToPrint');
      doc.html(elementHTML, {
        callback: function (doc) {
          doc.save(fileName);
        },
        margin: [2, 2, 2, 2],
        autoPaging: 'text',
        x: 0,
        y: 3,
        width: 292, // target width in the PDF document
        windowWidth: 1700, // window width in CSS pixels
      }).then(() => {
        this.spinner = false;
      });
      // setTimeout(function () {
      setTimeout(() => {
        this.pdfColor = '#F6F6F6';
      }, 0.1);
    },
  },
};
</script>
<style>
.v-data-table__expanded.v-data-table__expanded__content {
  box-shadow: none !important;
}
.v-data-table-header {
  background-color: lightgray;
  /*color: black;*/
}

</style>
