<template >
  <v-dialog
    v-model="localDialog"
    width="1100"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    persistent
  >
    <v-card flat fixed-header>
      <v-card-title class="card-header py-5" style="position: sticky; top: 0; z-index: 1;">
        <v-row  class="d-flex justify-end align-center">
          <span class="font-size15">{{ $t('medical-notes') }}</span>
          <v-spacer />
<!--        <v-chip-->
<!--            @click="downloadPDF(detailedUser.userData.firstName, detailedUser.userData.lastName)"-->
<!--            pill-->
<!--            small-->
<!--            label-->
<!--            medium-->
<!--            color="primary"-->
<!--            class="mx-1 text--black"-->
<!--            style="float:right"-->
<!--        >-->
<!--        {{ $t("download-medical-notes") }}-->
          <v-icon @click="downloadPDF(detailedUser.userData.firstName, detailedUser.userData.lastName)" class="mr-5 white-icon">mdi-cloud-download</v-icon>
<!--        </v-chip>-->
<!--        <v-chip-->
<!--            @click="vitalsPdf()"-->
<!--            pill-->
<!--            small-->
<!--            label-->
<!--            medium-->
<!--            color="primary"-->
<!--            class="mx-1 text--black"-->
<!--            style="float:right"-->
<!--        >-->
<!--          Download Vitals-->
<!--        </v-chip>-->
          <v-icon color="white" @click="closeDialog">mdi-window-close</v-icon>
          </v-row>
      </v-card-title>
      <v-card-text id="contentToPrint">
<!--        <v-row class="my-3" style="border-top: 9px solid white;">-->
<!--        </v-row>-->
        <v-row style="padding-bottom: 24px; margin-bottom: 0px; margin-top: 24px; font-family: Arial;">
          <v-col  style="text-align: left; margin-left: 24px; margin-right: 16px; padding-right: 64px">
            <v-row><span style="font-weight: bold" class="black--text">{{detailedUser.userData?.firstName + ' ' + detailedUser.userData?.lastName}}</span></v-row>
            <v-row><span class="black--text">{{detailedUser.userData?.address}}</span></v-row>
            <v-row><span class="black--text">{{phone(this.detailedUser.userData?.phone)}}</span></v-row>
            <v-row><span class="black--text">{{detailedUser.userData?.email}}</span></v-row>
            <v-row><span class="black--text">{{detailedUser?.userData?.patientMetadata?.medicalRegistrationNumber ? detailedUser?.userData?.patientMetadata?.medicalRegistrationNumber : ''}}</span></v-row>
          </v-col>
          <v-col style="text-align: left ;margin-left: 4px; margin-right: 4px;">
            <v-row style="font-weight: bold; color:black;">{{detailedUser.medicalTeamData[0]?.hospitalName}}</v-row>
            <!-- <v-row style="text-right: right"><span>{{hospitals[0]?.address}}</span></v-row> -->
            <v-row  class="black--text">{{ hospitals[0]?.address }}</v-row>
            <v-row class="black--text">{{hospitals[0]?.contactPhone}}</v-row>
            <v-row  class="black--text">{{hospitals[0]?.contactEmail}}</v-row>
          </v-col>
        </v-row>
      <v-col >
<!--       PROBLEM LIST -->
        <v-col style="font-size: 15px;">
          <v-row style="font-family: Arial; color:black; font-weight:bold;">
            {{ $t("problem-list") }}
          </v-row>
        </v-col>
        <v-card
            :color="pdfColor"
            :style="showBorder ? 'background: #F4F4F4;' : '' "
        >
          <v-card-text>
            <div style="text-align: justify; align-items: center">
              <span style="font-family: Arial; color:black;" v-html="getLatestDiagnosis"></span>
            </div>
            <div style="text-align: justify; align-items: center" v-if="detailedUser.userData.patientDiagnosisList.length === 0 ">
              <p>{{$t('no-diagnoses-available')}}</p>
            </div>
          </v-card-text>
        </v-card>
<!--        Home MED.-->
          <v-col style="font-size: 15px;">
            <v-row style="font-family: Arial; color:black; font-weight:bold; margin-top:5px;">
              {{ $t("home-medications") }}
            </v-row>
          </v-col>
        <v-card
            :color="pdfColor"
            :style="showBorder ? 'background: #F4F4F4;' : '' "
        >
          <v-card-text>
            <div style="text-align: justify; align-items: center">
              <span style="font-family: Arial; color:black;" v-html="detailedUser.userData.patientMetadata.prescriptions"></span>
            </div>
            <div style="text-align: justify; align-items: center" v-if="detailedUser.userData.patientMetadata.prescriptions === null || detailedUser.userData.patientMetadata.prescriptions === ''">
              <p>{{$t('no-treatments-available')}}</p>
            </div>
          </v-card-text>
        </v-card>
<!--        Allergies-->
        <v-col style="font-size: 15px;">
          <v-row style="font-family: Arial; color:black; font-weight:bold;">
              {{ $t("allergies") }}
            </v-row>
          </v-col>
        <v-card
            :color="pdfColor"
            :style="showBorder ? 'background: #F4F4F4;' : '' "
        >
          <v-card-text>
            <div style="text-align: justify; align-items: center">
              <span style="font-family: Arial; color:black;" v-html="detailedUser.userData.patientMetadata.metadataInfo"></span>
            </div>
            <div style="text-align: justify; align-items: center" v-if="detailedUser.userData.patientMetadata.metadataInfo === null">
              <p>{{$t('no-allergies-available')}}</p>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
<!--    Notes -->
        <v-col style=" font-family: Arial; font-size: 15px; padding-bottom:0px; text-align:left; color:black; font-weight:bold;">{{ $t("medical-notes") }}</v-col>
      <v-col style="padding-top: 0px" :style="showBorder ? '' : ''"  v-if="notesFromRepo" >
        <v-card
            elevation="1"
            class="pb-1"
            style="margin-bottom:10px;"
            :color="pdfColor"
            :style="showBorder ? 'background: #F4F4F4;' : '' "
            flat
            v-for="(item, i) in notesFromRepo"
            :key="i"
        >
          <v-card-title>
            <v-row class="font-size16" style="font-family: Arial;display: flex; justify-content: start; margin-left:1px;align-items: center; font-weight: bold">
              {{ item.medicalNoteCategoryTemplate?.medicalNoteCategoryName }}
            </v-row>
          </v-card-title>
          <v-card-text class="text-left my-0" v-for="detail in item.medicalNoteData.medicalNoteDetails" :key="detail.id">
            <span style="font-family: Arial; display: flex" v-html="formatContent(detail.medicalNoteContent)"></span>
            <span style="font-family: Arial; display: flex; font-weight: bold" class="d-flex font-size11 color-black">
              {{medicalStaffName(detail.medicalStaffId)}} : {{ formatDate(detail.timeAdded)}}
            </span>
            <v-divider></v-divider>
          </v-card-text>

          </v-card>

          <v-card
              :color="pdfColor"
              :style="showBorder ? 'background: #F4F4F4;' : '' "
              v-if="notesFromRepo.length === 0"
          >
            <v-card-text>
              <div style="text-align: justify; align-items: center">
                <p>{{$t('no-medical-notes-available')}}</p>
              </div>
            </v-card-text>
          </v-card>
      </v-col>

      <v-row>
      </v-row>

        </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import { AsYouType, isValidPhoneNumber } from 'libphonenumber-js';
import { allergies, calender, homemed, doctorBlack, email, height, location, phoneBlack, weight, perscription } from '@/assets/';
import jsPDF from 'jspdf';
import { fromTo } from '@/utils/unit-converter';
import { formatterFilter } from '@/utils/luxon-formater';
// import googleLineNew from '@/views/Client/components/GoogleLineNew';
export default {
	components: {
		// googleLineNew,
	},
	props: ['dialog', 'detailedUser', 'googleChartData', 'vitalsPDF'],
	data () {
		return {
			hospitals: [],
			chartData2: [
				['Month', 'Value One', 'Value Two'],
				['2004/05', 165, 320],
				['2004/05', 165, 320],
			],
			allergies: allergies,
      localDialog: this.dialog,
			calender: calender,
			homemed: homemed,
			email: email,
			height: height,
			location: location,
			perscription: perscription,
			phoneBlack: phoneBlack,
			weight: weight,
			doctorBlack: doctorBlack,
			pdfColor: '#f6f6f6',
			showBorder: false,
			timeLocalization: this.$i18n.locale,
			// doctor_black: doctorBlack,
		};
	},
	computed: {
		...mapState({
			notesFromRepo: (state) => state.appointments.notesForDownload,
			hospitalData: (state) => state.hospitals.hospitals,
      medicalStaff: (state) => state.hospitals.medicalStaff,
      userData: (state) => state.authentication.userData,

    }),
		...mapGetters({ roleName: 'authentication/getRole', userSettings: 'authentication/getUserSettings', hospitalId: 'authentication/getHospitalId' }),
		profileImage () {
			return this.detailedUser?.userProfileImage;
		},
    getLatestDiagnosis () {
      const diagnoses = this.detailedUser.userData.patientDiagnosisList.map(diagnosis => {
        return `<p class="mb-1"><b>${diagnosis.diagnosisCode}</b> ${diagnosis.diagnoseDescription}</p>`;
      });
      return `<div class="text-left px-0">${diagnoses.join('')}</div>`;
    },
		returnimage () {
			if (this.detailedUser?.userProfileImage) {
				return 'data:image/jpeg;base64,' + this.profileImage;
			} else { return 'https://icons.veryicon.com/png/o/object/material-design-icons-1/account-outline.png'; }
		},
		vitalsPdf () {
			return this.vitalsPDF;
		},
		// headers () {
		// 	var headers = [];
		// 	headers = [
		// 		{
		// 			text: this.$t('problem-list'),
		// 			sortable: false,
		// 			value: 'actions',
		// 		},
		// 		{
		// 			text: this.$t('home-medications'),
		// 			sortable: false,
		// 			value: 'actions',
		// 		},
		// 		{
		// 			text: this.$t('allergies'),
		// 			sortable: false,
		// 			value: 'actions',
		// 		},
		// 	];
		// 	return headers;
		// },
	},
	async mounted () {
    // await this.getAllNotes();
		await this.getHospitals();
		await this.getAllMedicalStaff();
    },
  // watch: {
  //   dialog (newVal) {
  //     if (!newVal) {
  //       this.localDialog = false;
  //     } else {
  //       this.localDialog = true;
  //     }
  //     // this.localDialog = !newVal; // update local data when prop changes
  //   },
  // },
	methods: {
    formatContent (content) {
      // Replace \n with <br>
      return content.replace(/\n/g, '<br>');
    },
    getAllMedicalStaff () {
      this.$store.dispatch('hospitals/getRelatedMedicalStaff', this.userData.hospitalAssignedData.id);
    },
    medicalStaffName (staffId) {
      if (this.medicalStaff && this.medicalStaff.length > 0) {
        const matchedStaff = this.medicalStaff.find(staff => staff.id === staffId);
        if (matchedStaff) {
          return `${matchedStaff.firstName} ${matchedStaff.lastName}`;
        }
      }
      return '';
    },
    formatDate (dateString) {
      return formatterFilter(
          dateString,
          'datetime_med',
          this.timeLocalization,
          null,
      );
    },
    // async getAllNotes () {
    //   this.loading = true;
    //   const body = {
    //     patientId: this.$route.params.id,
    //     hospitalId: this.hospitalId,
    //   };
    //   await this.$store.dispatch('appointments/getmedicalnoteforpatientbyid', body).then(res => {
    //     this.loading = false;
    //   });
    // },
    closeDialog () {
      this.localDialog = !this.localDialog;
      this.$emit('update:dialog', this.localDialog); // emit event to update par
    },
		async getHospitals () {
			await this.$store.dispatch('hospitals/getHospitals').then(() => {
				if (this.hospitalData !== null) {
					this.hospitals = this.hospitalData;
				} else this.hospitals = [];
			});
		},
		toFeet (value) {
			return fromTo(value, 'cm', 'ft-us').toFixed(2);
		},
		toLbs (value) {
			return fromTo(value, 'kg', 'lb').toFixed(0);
		},
		downloadPDF (firstName, lastName) {
			this.pdfColor = '#FFFFFF';
			this.showBorder = true;
			const fileName = 'Patient notes ' + firstName + ' ' + lastName + '.pdf';

			// eslint-disable-next-line new-cap
			var doc = new jsPDF('p', 'mm', [297, 210], true);
			var elementHTML = document.querySelector('#contentToPrint');
			doc.html(elementHTML, {
				callback: function (doc) {
					doc.save(fileName);
				},
				margin: [5, 5, 5, 5],
				autoPaging: 'text',
				x: 0,
				y: 3,
				width: 200, // target width in the PDF document
				windowWidth: 900, // window width in CSS pixels
			});
			// setTimeout(function () {
			setTimeout(() => {
				this.pdfColor = '#F6F6F6';
				this.showBorder = false;
			}, 0.1);
		},
		phone (val) {
			if (val !== undefined) {
				if (!isValidPhoneNumber(val)) {
					this.phoneErrors = true;
				} else {
					this.phoneErrors = false;
				}
				return new AsYouType().input(val);
			}
		},
		calculateAge (dateString) {
			var today = new Date();
			var birthDate = new Date(dateString.split('T')[0]);
			var age = today.getFullYear() - birthDate.getFullYear();
			var m = today.getMonth() - birthDate.getMonth();
			if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
				age--;
			}
			return age;
		},
	},
};
</script>
<style>
</style>
