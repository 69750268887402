<template>
	<v-dialog v-if="showDialog" v-model="showDialog" max-width="600px" persistent>
		<v-card flat class="overflow-auto">
			<v-card-title class="card-header text-left">
				<div class="font-size18">{{$t('exit-patient-details-dialog-alert')}}</div>
				<v-spacer></v-spacer>
        <v-icon @click="emitEventToExitPatientDetails" color="white">mdi mdi-close</v-icon>
				<!-- <v-btn icon small color="primary" @click="emitEventToExitPatientDetails">
					<v-icon>mdi-close</v-icon>
				</v-btn> -->
			</v-card-title>
			<v-card-text>
				<v-container>
					<v-row >
						<div class="mt-5">
							<span class="mt-5 confirm-dialog">{{ $t('exit-patient-details-dialog-content') }}</span>
            </div>
					</v-row>
				</v-container>
			</v-card-text>
			<v-card-actions class="mx-auto d-flex align-center justify-center">
        <v-row dense>
          <v-col cols="6">
            <v-btn color="secondary" block class="color-black" dense @click="emitEventToExitPatientDetails">{{ $t('no') }}</v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn block color="primary2" dense @click="emitEventToStayPatientDetails" class="text-color-white">{{ $t('yes') }}</v-btn>
          </v-col>
        </v-row>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	data () {
		return {
		};
	},
	props: ['showDialog'],
	methods: {
		emitEventToExitPatientDetails () {
			this.$emit('exitPatientFromPatientDetails');
		},
		emitEventToStayPatientDetails () {
			this.$emit('stayPatientFromPatientDetails');
		},

	},
};
</script>
