<template>
  <v-dialog
      v-model="localDialog"
      width="400"
      persistent
      :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card class="overflow-hidden">
      <v-card-title class="d-flex align-center justify-space-between primary2 sticky-title">
        <span style="text-transform: capitalize; color: white">{{this.vitals?.deviceName}}</span>
        <v-icon @click="closeDialog" color="white">mdi-close</v-icon>
      </v-card-title>
      <v-card-text>

        <!--
         Oxy - 2
         Glucose - 6
         Blood Pressure - 3
         Weight Scale - 5
         Thermometer - 1
         -->
        <template v-if="vitals?.deviceType === 6">
          <v-select class="mt-2" outlined dense label="Measurement done before or after meal"  hide-details :items="beforeMeal" item-text="text" item-value="value" v-model="selectionBeforeMeal">

          </v-select>
          <v-text-field
              v-if="selectionBeforeMeal !==null"
              outlined
              dense
              :label="getLabelNames(vitals.deviceType ,1)"
              :rules="[v => !!v || 'Value is required', validateValue(vitals.deviceType, 1)]"
              v-model="value1"
              class="pt-2">
          </v-text-field>
        </template>
        <template v-if="vitals?.deviceType === 5 || vitals?.deviceType === 1 ||  vitals?.deviceType === 7">
          <v-text-field
              outlined
              dense
              :label="getLabelNames(vitals.deviceType ,1)"
              :rules="[v => !!v || 'Value is required', validateValue(vitals.deviceType, 1)]"
              v-model="value1"
              class="pt-2">
          </v-text-field>
        </template>
        <template v-if="vitals?.deviceType === 2">
          <v-text-field
              outlined
              dense
              :label="getLabelNames(vitals.deviceType ,1)"
              :rules="[v => !!v || 'Value is required', validateValue(vitals.deviceType, 1)]"
              v-model="value1"
              class="pt-2">
          </v-text-field>
          <v-text-field
              outlined
              dense
              :label="getLabelNames(vitals.deviceType ,2)"
              :rules="[v => !!v || 'Value is required', validateValue(vitals.deviceType, 2)]"
              v-model="value2"
              class="pt-2">
          </v-text-field>
        </template>
        <template v-if="vitals?.deviceType === 3">
          <v-text-field
              outlined
              dense
              v-model="value1"
              :label="getLabelNames(vitals.deviceType ,1)"
              :rules="[v => !!v || 'Value is required', validateValue(vitals.deviceType, 1)]"
              class="pt-2">
          </v-text-field>
          <v-text-field
              outlined
              dense
              v-model="value2"
              :label="getLabelNames(vitals.deviceType ,2)"
              :rules="[v => !!v || 'Value is required', validateValue(vitals.deviceType, 2)]"
              class="pt-2">
          </v-text-field>
          <v-text-field
              outlined
              dense
              v-model="value3"
              :label="getLabelNames(vitals.deviceType ,3)"
              :rules="[v => !!v || 'Value is required', validateValue(vitals.deviceType, 3)]"
              class="pt-2">
          </v-text-field>
        </template>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <div class="ml-auto mb-3 mr-5">
            <v-btn @click="closeDialog">Close</v-btn>
            <v-btn @click="sendMeasurement" color="primary2"><span style="color: white">Save</span></v-btn>
          </div>

        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import { fromTo } from '@/utils/unit-converter';
export default {
  props: ['dialog', 'vitals'],
  data () {
    return {
      selectionBeforeMeal: null,
      beforeMeal: [{ text: 'Before Meal', value: true }, { text: 'After meal', value: false }],
      localDialog: this.dialog,
      value1: null,
      value2: null,
      value3: null,

      disable1: true,
      disable2: true,
      disable3: true,

      tempInC: null,
      tempInF: null,

      SpO2: null,
      systolic: null,
      diastolic: null,
      pulse: null,

      weightInKg: null,
      weightInlbs: null,

      bloodSugarInmmolL: null,
      bloodSugarInmgdl: null,

      cholesterolInmmolL: null,
      cholesterolInmgdl: null,
      disabled: true,
    };
  },
  methods: {
    resetFields () {
      window.location.reload();
    },
    async getHubsData (data) {
      data.filter = 'LAST14';
      await this.$store.dispatch('nosessionhubs/getHubsData', data);
    },
    getLabelNames (deviceType, position) {
      switch (deviceType) {
        case 1:
          return 'Enter temperature in: ' + (this.userSettings.Units.toLowerCase() === 'metric' ? '°C' : '°F');
        case 2:
          if (position === 1) {
            return 'SPo2 in %';
          } else {
           return 'Pulse';
          }
        case 3:
          if (position === 1) {
            return 'Systolic in mmHg';
          }
          if (position === 2) {
            return 'Diastolic in mmHg';
          }
          if (position === 3) {
            return 'Pulse';
          }
          break;
        case 5:
          return 'Enter weight in: ' + (this.userSettings.Units.toLowerCase() === 'metric' ? 'Kg' : 'lbs');
        case 6:
          return (this.userSettings.Concentration.toLowerCase() === 'mg/dl' ? 'mg/dL' : 'mmol/L');
        case 7:
          return (this.userSettings.Concentration.toLowerCase() === 'mg/dl' ? 'mg/dL' : 'mmol/L');
      }
    },
    createRequest () {
      // const body = {
      //   hubIdentifier:this.vitals.hubIdentifier,
      //   deviceType:
      //   deviceIdentifier:
      //   recordedAt:
      //   measuredDataJson:
      //   patientId:
      //   measurementDoneByUserId:
      //   referenceData:
      //   measurementStatus:
      // };
    },
    validateValue (deviceType, position) {
      return value => {
        const extremeValues = JSON.parse(this.vitals.deviceDefaultExtremeValuesJson);
        switch (deviceType) {
          case 1:
            {
                if (this.userSettings.Units.toLowerCase() === 'metric') {
                  this.tempInC = this.value1;
                  this.tempInF = fromTo(this.value1, 'C', 'F').toFixed(2);
                } else {
                  this.tempInC = fromTo(this.value1, 'F', 'C').toFixed(2);
                  this.tempInF = this.value1;
                }
              if (this.tempInC < extremeValues.MinTemperature || this.tempInC > extremeValues.MaxTemperature) {
                this.disabled = true;
                return 'Value is out of range';
              }
              this.disabled = false;
              return true;
            }
          case 2:
          {
            if (position === 1) {
              if (this.value1 < extremeValues.SpO2 || this.value1 > 100) {
                this.disable1 = true;
                this.disabled = this.disable1 || this.disable2;

                return 'Value is out of range';
              }
              this.SpO2 = this.value1;
              this.disable1 = false;
              this.disabled = this.disable1 || this.disable2;
              return true;
            }
            if (position === 2) {
              if (this.value2 < extremeValues.HeartBeatMin || this.value2 > extremeValues.HeartBeatMax) {
                this.disable2 = true;
                this.disabled = this.disable1 || this.disable2;
                return 'Value is out of range';
              }
              this.pulse = this.value2;
              this.disable2 = false;
              this.disabled = this.disable1 || this.disable2;

              return true;
            }

            return false;
          }
          case 3:
          {
            if (position === 1) {
              if (this.value1 < extremeValues.SystolicMin || this.value1 > extremeValues.SystolicMax) {
                this.disable1 = true;
                this.disabled = this.disable1 || this.disable2 || this.disable3;
                return 'Value is out of range';
              }
              this.disable1 = false;
              this.disabled = this.disable1 || this.disable2 || this.disable3;
              this.systolic = this.value1;
              return true;
            }
            if (position === 2) {
              if (this.value2 < extremeValues.DiastolicMin || this.value2 > extremeValues.DiastolicMax) {
                this.disable2 = true;
                this.disabled = this.disable1 || this.disable2 || this.disable3;
                return 'Value is out of range';
              }
              this.diastolic = this.value2;
              this.disable2 = false;
              this.disabled = this.disable1 || this.disable2 || this.disable3;
              return true;
            }
            if (position === 3) {
              if (this.value3 < extremeValues.HeartBeatMin || this.value3 > extremeValues.HeartBeatMax) {
                this.disable3 = true;
                this.disabled = this.disable1 || this.disable2 || this.disable3;
                return 'Value is out of range';
              }
              this.pulse = this.value3;
              this.disable3 = false;
              this.disabled = this.disable1 || this.disable2 || this.disable3;
              return true;
            }
            return false;
          }
          case 5:
          {
            if (this.userSettings.Units.toLowerCase() === 'metric') {
              this.weightInKg = this.value1;
              //	return fromTo(value, 'kg', 'lb').toFixed(0);
              this.weightInlbs = fromTo(this.value1, 'kg', 'lb').toFixed(1);
            } else {
              this.weightInKg = fromTo(this.value1, 'lb', 'kg').toFixed(1);
              this.weightInlbs = this.value1;
            }
            if (this.weightInKg <= 0 || this.weightInKg > 400) {
              this.disabled = true;
              return 'Value is out of range';
            } else {
              this.disabled = false;
            }

            return true;
          }
          case 6:
          {
            if (this.userSettings.Concentration.toLowerCase() === 'mmol/l') {
              this.bloodSugarInmmolL = this.value1;
              //	return fromTo(value, 'kg', 'lb').toFixed(0);
              this.bloodSugarInmgdl = this.value1 * 18.018;
            } else {
              this.bloodSugarInmmolL = this.value1 * 0.0555;
              //	return fromTo(value, 'kg', 'lb').toFixed(0);
              this.bloodSugarInmgdl = this.value1;
            }

            if (this.bloodSugarInmmolL < extremeValues.BloodSugarLevelMin || this.bloodSugarInmmolL > extremeValues.BloodSugarLevelMax) {
              this.disabled = true;
              return 'Value is out of range';
            }
              this.disabled = false;
            return true;
          }
          case 7:
          {
            if (this.userSettings.Concentration.toLowerCase() === 'mmol/l') {
              this.cholesterolInmmolL = this.value1;
              //	return fromTo(value, 'kg', 'lb').toFixed(0);
              this.cholesterolInmgdl = this.value1 * 38.67;
            } else {
              this.cholesterolInmmolL = this.value1 / 38.67;
              //	return fromTo(value, 'kg', 'lb').toFixed(0);
              this.cholesterolInmgdl = this.value1;
            }

            if (this.value1 < extremeValues.CholesterolLevelMin || this.value1 > extremeValues.CholesterolLevelMax) {
              return 'Value is out of range';
            }
            return true;
          }
        }
      };
    },
    sendMeasurement () {
      // {"MeasurementStatus":1,"TemperatureBodyType":2,"Temperature_C":36.7,"Temperature_F":98.1,"device_id":"33767a66-d0df-4ccc-a612-72a031de515b","device_type":"1"}
      const measurementStatus = 1;
      let measuredDataJson = {};
      switch (this.vitals.deviceType) {
        case 1:
          measuredDataJson = {
            MeasurementStatus: measurementStatus,
            TemperatureBodyType: 2,
            Temperature_C: parseFloat(this.tempInC),
            Temperature_F: parseFloat(this.tempInF),
            device_id: this.vitals.device.deviceIdentifier,
            device_type: this.vitals.deviceType,
          };
          break;
        case 2:
          measuredDataJson = {
            BPM: parseFloat(this.pulse),
            PI: parseFloat(10),
            Spo2: parseFloat(this.SpO2),
            device_id: this.vitals.device.deviceIdentifier,
            device_type: this.vitals.deviceType,
          };
          break;
        //   Type = 3
        //
        // {"Dias":58,"MeasurementStatus":1,"Measurement_Status":0,"PAD_Detected":0,"Pul":74,"Sys":97,"device_id":"32156bbe-5aed-439e-979a-e79ea31f3160","device_type":"3"}
        //
        //   Type = 5
        //
        // {"MeasurementStatus":1,"device_id":"7263fd47-ca87-45b6-bd70-fdca60031010","device_type":"5","impedance_status":2,"isbattery_low":0,"measurement_unit":0,"weight":90.6,"weight_kg":90.6,"weight_lbs":199.8}
        //
        //   Type = 6
        //
        // {"MeasurementStatus":1,"device_id":"c21bb480-27ce-4d24-8fbc-9b579821ad01","device_type":"6","glucose_mgL":99.0,"glucose_mmolL":5.5,"isMeasuredBeforeMeal":true,"time":"2024-02-27T12:03:33.652"}
        //
        //   Type = 7
        //
        // {"MeasurementStatus":1,"cholesterol_mgL":108.0,"cholesterol_mmolL":6.0,"device_id":"B4:52:A9:C8:68:78","device_type":"7","glucose_mgL":0.0,"glucose_mmolL":0.0}
        case 3:
          measuredDataJson = {
            MeasurementStatus: measurementStatus,
            Measurement_Status: 0,
            Sys: parseFloat(this.systolic),
            Dias: parseFloat(this.diastolic),
            Pul: parseFloat(this.pulse),
            PAD_Detected: parseFloat(0),
            device_id: this.vitals.device.deviceIdentifier,
            device_type: this.vitals.deviceType,
          };
          break;
        case 5:
          measuredDataJson = {
            MeasurementStatus: measurementStatus,
            impedance_status: 2,
            isbattery_low: 0,
            measurement_unit: 0,
            weight: parseFloat(this.weightInKg),
            weight_kg: parseFloat(this.weightInKg),
            weight_lbs: parseFloat(this.weightInlbs),
            device_id: this.vitals.device.deviceIdentifier,
            device_type: this.vitals.deviceType,
          };
          break;
          // {"MeasurementStatus":1,"device_id":"c21bb480-27ce-4d24-8fbc-9b579821ad01","device_type":"6","glucose_mgL":99.0,"glucose_mmolL":5.5,"isMeasuredBeforeMeal":true,"time":"2024-02-27T12:03:33.652"}
        case 6:
          measuredDataJson = {
            MeasurementStatus: measurementStatus,
            glucose_mgL: parseFloat(this.bloodSugarInmgdl),
            glucose_mmolL: parseFloat(this.bloodSugarInmmolL),
            time: new Date().toISOString(),
            isMeasuredBeforeMeal: this.selectionBeforeMeal,
            device_id: this.vitals.device.deviceIdentifier,
            device_type: this.vitals.deviceType,
          };
          break;
          //   // {"MeasurementStatus":1,"cholesterol_mgL":108.0,"cholesterol_mmolL":6.0,"device_id":"B4:52:A9:C8:68:78","device_type":"7","glucose_mgL":0.0,"glucose_mmolL":0.0}
        case 7:
          measuredDataJson = {
            MeasurementStatus: measurementStatus,
            cholesterol_mgL: parseFloat(this.cholesterolInmgdl),
            cholesterol_mmolL: parseFloat(this.cholesterolInmmolL),
            glucose_mgL: parseFloat(0),
            glucose_mmolL: parseFloat(0),
            device_id: this.vitals.device.deviceIdentifier,
            device_type: this.vitals.deviceType,
          };
          break;
      }

      const body = {
        hubIdentifier: this.vitals.device.hubIdentifier,
        deviceIdentifier: this.vitals.device.deviceIdentifier,
        deviceType: parseInt(this.vitals.deviceType),
        measuredAt: new Date().toISOString(),
        measuredDataJson: JSON.stringify(measuredDataJson),
        patientId: this.detailedUser.userData.id,
        recordedAt: new Date().toISOString(),
        referenceData: null,
        MeasurementStatus: measurementStatus,
        measurementDoneByUserId: this.authenticated.id,
      };
      this.$store.dispatch('medicaldevice/sendMeasurement', body).then(res => {
        const data = {
          id: this.detailedUser.userData.id,
          filter: 'LAST14',
        };
        this.resetFields();
        this.$store.dispatch('nosessionhubs/getHubsData', data);
      });
    },
    closeDialog () {
      this.localDialog = !this.localDialog;
      this.$emit('update:dialog', this.localDialog); // emit event to update par
      this.value1 = '';
      this.value2 = '';
      this.value3 = '';
    },
  },
  //  userSettings: 'authentication/getUserSettings',
  computed: {
    ...mapState({
      authenticated: state => state.authentication.userData,
      detailedUser: (state) => state.users.detailedUser,
    }),
    ...mapGetters({
      userSettings: 'authentication/getUserSettings',
    }),
  },
  watch: {
    dialog (newVal) {
      if (!newVal) {
        this.localDialog = true;
      } else {
        this.localDialog = true;
      }
      // this.localDialog = !newVal; // update local data when prop changes
    },
  },
};
</script>
<style>
</style>
