<template>
  <div class="d-flex align-end justify-center mr-3" ref="graphContainer" id="graphContainer" style="height: 100%;" v-resize="resize" >
    <Graph
        v-if="periodData"
        ref="graph"
        :key="rerender"
        :width="graphContainerSize[0]+'px'"
        :height="graphContainerSize[1]+'px'"
        :options="ComputedChartOptions"
        :series="DataSeries">
    </Graph>
    <div v-if="deviceType === 5">
      <Graph
          v-if="periodData"
          ref="graph"
          :height="300"
          :key="rerender"
          :options="ComputedChartOptions"
          :series="DataSeries">
      </Graph>
    </div>
  </div>
</template>

<script>
// https://apexcharts.com/vue-chart-demos/mixed-charts/line-column-area/
import Graph from 'vue-apexcharts';
import convert from 'convert-units';

export default {
  props: {
    // concentration: Boolean,
    unitSystem: null,
    thresholdToggle: Boolean,
    thresholdsValues: null,
    extreamThreshHolds: null,
    graphData: null,
  },
  data: function () {
    return {
      rerender: 1,
      deviceType: null,
      // thresholdToggle: false,
      vitalUnit: '',
      units: ['mg/dl', 'mmol/l'],
      periodData: true,
      graphContainerSize: [1500, 0],
      chartOptions: {
        grid: {
          padding: {
            // right: 15,
          },
        },
        chart: {
          id: 'Graph',
          type: 'line',
          parentHeightOffset: 0,
          redrawOnParentResize: true,
          zoom: {
            type: 'x',
            enabled: true,
            autoScaleYaxis: true,
          },
          toolbar: {
            show: true,
            autoSelected: 'zoom',
          },
        },

        stroke: {
          width: 2,
          curve: 'smooth',
          // colors: ['#09cb09'],
        },

        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            // gradientFromColors: ['#12ff5d'],
            gradientToColors: ['#e40d34'],
            shadeIntensity: 1,
            type: 'vertical',
            opacityFrom: 1,
            opacityTo: 1,
            stops: [10, 80],
          },
        },

        yaxis: {
          // min: 0,
          // max: 100,
          autoScaleYaxis: true,
        },

        xaxis: {
          type: 'numeric',
          labels: {
            show: true,
            style: {
              fontSize: '0px',
            },
          },
          axisTicks: {
            show: false,
          },
          tooltip: {
            enabled: false,
          },
        },

        tooltip: {
          x: {
            show: true,
            formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
              value = w.globals.initialSeries[seriesIndex].data[dataPointIndex].label;
              return value;
            },
          },
          // 	custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          // 		const data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
          // 		return (
          // 			'<div>' +
          //     '<span>' +
          //     data.label +
          //     '</span>' +
          //     '</div>'
          // 		);
          // 	},
        },

      },

      annotations: {
        yaxis: [
          {
            id: 'MinThreshold',
            y: 0,
            strokeDashArray: 0,
            borderColor: '#0496ff',
            label: {
              offsetY: 22,
              borderColor: '#0496ffdc',
              style: {
                background: '#ffffffcf',
                color: '#0496ff',
                fontSize: '10px',
                padding: {
                  left: 2,
                  right: 2,
                  top: 0,
                  bottom: 0,
                },
              },
              text: '',
            },
          },
          {
            id: 'MaxThreshold',
            y: 0,
            strokeDashArray: 0,
            borderColor: '#e40d34',
            label: {
              borderColor: '#e40d34',
              offsetY: -5,
              style: {
                background: '#ffffffcf',
                fontSize: '10px',
                color: '#e40d34',
                padding: {
                  left: 2,
                  right: 2,
                  top: 0,
                  bottom: 0,
                },
              },
              text: '',
            },
          },
		{
            id: 'MinThreshold1',
            y: 0,
            strokeDashArray: 0,
            borderColor: '#0496ff',
            label: {
              offsetY: 22,
              borderColor: '#0496ffdc',
              style: {
                background: '#ffffffcf',
                color: '#0496ff',
                fontSize: '10px',
                padding: {
                  left: 2,
                  right: 2,
                  top: 0,
                  bottom: 0,
                },
              },
              text: '',
            },
          },
          {
            id: 'MaxThreshold1',
            y: 0,
            strokeDashArray: 0,
            borderColor: '#e40d34',
            label: {
              borderColor: '#e40d34',
              offsetY: -5,
              style: {
                background: '#ffffffcf',
                fontSize: '10px',
                color: '#e40d34',
                padding: {
                  left: 2,
                  right: 2,
                  top: 0,
                  bottom: 0,
                },
              },
              text: '',
            },
          },
        ],
        // xaxis: [
        // 	{
        // 		x: 100,
        // 		x2: 210,
        // 		opacity: 0.1,
        // 		borderColor: '#e40d34',
        // 		offsetY: 25,
        // 		fillColor: '#e40d34',
        // 		label: {
        // 			style: {
        // 				color: '#e40d34',
        // 			},
        // 		},
        // 	},

        // 	{
        // 		x: 560,
        // 		x2: 750,
        // 		opacity: 0.1,
        // 		borderColor: '#09cb09',
        // 		offsetY: 250,
        // 		fillColor: '#09cb09',
        // 		label: {
        // 			style: {
        // 				color: '#e40d34',
        // 			},
        // 		},
        // 	},
        // ],

        points: [
          {
            x: ' ',
            y: 1,
            marker: {
              size: 5,
              fillColor: '#e40d34',
              strokeColor: '#fff',
              radius: 2,
            },
            label: {
              borderColor: '#e40d34',
              offsetY: 0,
              style: {
                color: '#fff',
                background: '#e40d34',
              },

              text: 'val unit',
            },
          },

          {
            x: ' ',
            y: 0,
            marker: {
              size: 5,
              fillColor: '#09cb09',
              strokeColor: '#fff',
              radius: 2,
            },
            label: {
              borderColor: '#09cb09',
              offsetY: 0,
              style: {
                color: '#fff',
                background: '#09cb09',
              },

              text: 'val unit',
            },
          },
        ],
      },

    };
  },
  components: {
    Graph,
  },
  async mounted () {
    this.graphContainerSize[0] = await this.$refs.graphContainer.clientWidth;
    this.graphContainerSize[1] = await this.$refs.graphContainer.clientHeight;
  },
  computed: {

    ComputedChartOptions () {
      const Opts = this.chartOptions;
      this.setThresholds();
      const Thresholds = { defaults: this.annotations.yaxis, minMax: this.annotations.points };
      // Opts.labels = this.getLabels();
      const extremes = this.getExtremeValues(Thresholds);
      Opts.annotations = this.annotations;
      Opts.yaxis.min = extremes[0];
      Opts.yaxis.max = extremes[1];
      return Opts;
    },

    DataSeries () {
      var Series = [{ data: [] }];
      var index = 0;
      var minMax = { min: { x: 0, y: 1000 }, max: { x: 0, y: 0 } };
      const dataSize = this.graphData.length;
      if (dataSize === 0) {
        this.hideGraph();
        return Series;
      }

      const deviceType = parseInt(this.graphData[0].device_type);

      switch (deviceType) {
        case 1:
          Series[0].name = 'Temperature';
          break;

        case 2:
          Series[0].name = 'Sp02';
          break;

        case 3:
          Series = [{ name: 'Systolic', data: [] }, { name: 'Diastolic', data: [] }];
          break;

        case 5:
          Series[0].name = this.$t('weight');
          break;

        case 6:
          Series[0].name = this.$t('blood-glucose');
          break;

        case 7:
          Series[0].name = this.$t('cholesterol');
          break;

        default:
          Series[0].name = 'unknown';
          break;
      }

      this.graphData.forEach((measurement) => {
        Series[0].data.push({ x: dataSize - index });
        Series[0].data[index].label = measurement.measuredAtFormatted;

        switch (deviceType) {
          case 1:
            if (this.unitSystem.unitSystem === 'imperial') {
              Series[0].data[index].y = measurement.Temperature_F;
            } else {
              Series[0].data[index].y = measurement.Temperature_C;
            }
            break;

          case 2:
            Series[0].data[index].y = measurement.Spo2;
            break;

          case 3:
            Series[0].data[index].y = measurement.Sys;
            Series[1].data.push({ x: dataSize - index });
            Series[1].data[index].label = measurement.measuredAtFormatted;
            Series[1].data[index].y = measurement.Dias;
            break;

          case 5:
            if (this.unitSystem.unitSystem === 'imperial') {
              Series[0].data[index].y = measurement.weight_lbs;
            } else {
              Series[0].data[index].y = measurement.weight_kg;
            }
            break;

          case 6:
            if (this.unitSystem.concentration === 'mg/dl') {
              Series[0].data[index].y = measurement.glucose_mgL;
            } else {
              Series[0].data[index].y = measurement.glucose_mmolL;
            }
            break;

          case 7:
            if (this.unitSystem.concentration === 'mg/dl') {
              Series[0].data[index].y = measurement.cholesterol_mgL;
            } else {
              Series[0].data[index].y = measurement.cholesterol_mmolL;
            }
            break;

          default:
            break;
        }

		if (deviceType === 3) {
			const currentNumber1 = Number.parseFloat(Series[0].data[index].y);
			if (currentNumber1 > minMax.max.y) {
				minMax.max.y = currentNumber1;
				minMax.max.x = Series[0].data[index].x;
			}
			// else if (currentNumber < minMax.min.y) {
			// 	minMax.min.y = currentNumber;
			// 	minMax.min.x = Series[0].data[index].x;
			// } else {
			// 	// this.debug(['MinMax', minMax]);
			// }

			const currentNumber2 = Number.parseFloat(Series[1].data[index].y);
			// if (currentNumber > minMax.max.y) {
			// 	minMax.max.y = currentNumber;
			// 	minMax.max.x = Series[0].data[index].x;
			// } else
			if (currentNumber2 < minMax.min.y) {
				minMax.min.y = currentNumber2;
				minMax.min.x = Series[1].data[index].x;
			}
		} else {
			const currentNumber = Number.parseFloat(Series[0].data[index].y);
			if (currentNumber > minMax.max.y) {
				minMax.max.y = currentNumber;
				minMax.max.x = Series[0].data[index].x;
			} else if (currentNumber < minMax.min.y) {
				minMax.min.y = currentNumber;
				minMax.min.x = Series[0].data[index].x;
			} else {
				// this.debug(['MinMax', minMax]);
			}
		}
        index++;
      });

      this.getMinMax(minMax, dataSize);

	if (deviceType === '3') {
		Series[0].data.reverse();
		Series[1].data.reverse();
	} else {
		Series[0].data.reverse();
	} // Reverse diastolic data if device type is 3

      return Series;
    },
  },
  methods: {

    resize () {
      this.graphContainerSize[0] = this.$refs.graphContainer.clientWidth;
      this.graphContainerSize[1] = this.$refs.graphContainer.clientHeight;
      this.rerender = !this.rerender;
    },

    hideGraph () {
      this.periodData = false;
    },

    // toggleDetails () {
    // },
    //
    // getLabels () {
    //   const labels = [];
    //   // var index = this.graphData.length;
    //
    //   this.graphData.forEach(measurement => {
    //     labels.push(measurement.measuredAtFormatted);
    //     // index--;
    //   });
    //   labels.reverse();
    //
    //   return labels;
    // },

    getMinMax (minMaxVals, dataSize) {
      const deviceType = parseInt(this.graphData[0].device_type);
      this.deviceType = deviceType;

      if (minMaxVals.max.x > (dataSize * 0.9)) {
        this.annotations.points[0].label.textAnchor = 'end';
      } else if (minMaxVals.max.x < (dataSize * 0.1)) {
        this.annotations.points[0].label.textAnchor = 'start';
      }

      if (minMaxVals.min.x > (dataSize * 0.9)) {
        this.annotations.points[1].label.textAnchor = 'end';
      } else if (minMaxVals.min.x < (dataSize * 0.1)) {
        this.annotations.points[1].label.textAnchor = 'start';
      }

      this.annotations.points[0].y = minMaxVals.max.y;
      this.annotations.points[0].x = minMaxVals.max.x;
      this.annotations.points[1].y = minMaxVals.min.y;
      this.annotations.points[1].x = minMaxVals.min.x;

      switch (deviceType) {
        case 1:
          if (this.unitSystem.unitSystem === 'imperial') {
            this.annotations.points[0].label.text = minMaxVals.max.y + ' F°';
            this.annotations.points[1].label.text = minMaxVals.min.y + ' F°';
          } else {
            this.annotations.points[0].label.text = minMaxVals.max.y + ' C°';
            this.annotations.points[1].label.text = minMaxVals.min.y + ' C°';
          }
          break;

        case 2:
          this.annotations.points[0].label.text = minMaxVals.max.y + ' SpO2';
          this.annotations.points[1].label.text = minMaxVals.min.y + ' SpO2';
          break;

        case 3:
          this.annotations.points[0].label.text = minMaxVals.max.y + ' mmHg';
          this.annotations.points[1].label.text = minMaxVals.min.y + ' mmHg';
          break;

        case 5:
          if (this.unitSystem.unitSystem === 'imperial') {
            this.annotations.points[0].label.text = minMaxVals.max.y + ' lb';
            this.annotations.points[1].label.text = minMaxVals.min.y + ' lb';
          } else {
            this.annotations.points[0].label.text = minMaxVals.max.y + ' kg';
            this.annotations.points[1].label.text = minMaxVals.min.y + ' kg';
          }
          break;

        case 6:
          if (this.unitSystem.concentration === 'mg/dl') {
            this.annotations.points[0].label.text = minMaxVals.max.y + ' mg/dl';
            this.annotations.points[1].label.text = minMaxVals.min.y + ' mg/dl';
          } else {
            this.annotations.points[0].label.text = minMaxVals.max.y + ' mmol/l';
            this.annotations.points[1].label.text = minMaxVals.min.y + ' mmol/l';
          }
          break;

        case 7:
          if (this.unitSystem.concentration === 'mg/dl') {
            this.annotations.points[0].label.text = minMaxVals.max.y + ' mg/dl';
            this.annotations.points[1].label.text = minMaxVals.min.y + ' mg/dl';
          } else {
            this.annotations.points[0].label.text = minMaxVals.max.y + ' mmol/l';
            this.annotations.points[1].label.text = minMaxVals.min.y + ' mmol/l';
          }
          break;

        default:
          break;
      }
    },

    setThresholds () {
      const deviceType = parseInt(this.graphData[0].device_type);

      switch (deviceType) {
        case 1:
          if (this.unitSystem.unitSystem === 'imperial') {
            this.annotations.yaxis[0].y = convert(this.thresholdsValues.MinTemperature).from('C').to('F');
            this.annotations.yaxis[1].y = convert(this.thresholdsValues.MaxTemperature).from('C').to('F');
            this.annotations.yaxis[0].label.text = this.annotations.yaxis[0].y.toFixed(2) + ' F°';
            this.annotations.yaxis[1].label.text = this.annotations.yaxis[1].y.toFixed(2) + ' F°';
          } else {
            this.annotations.yaxis[0].y = 35.6;
            // this.annotations.yaxis[0].y = this.thresholdsValues.MinTemperature;
            this.annotations.yaxis[1].y = 40.0;
            // this.annotations.yaxis[1].y = this.thresholdsValues.MaxTemperature;
            this.annotations.yaxis[0].label.text = 35.6 + ' C°';
            this.annotations.yaxis[1].label.text = 40.0 + ' C°';
          }
          break;

        case 2:
          this.annotations.yaxis[0].y = this.thresholdsValues.SpO2;
          this.annotations.yaxis[0].label.text = this.thresholdsValues.SpO2 + ' SpO2';
          this.annotations.yaxis[1].y = 100;
          this.annotations.yaxis[1].label.text = 100 + ' SpO2';
          break;

        case 3:
			this.annotations.yaxis[0].y = this.thresholdsValues.SystolicMin;
			this.annotations.yaxis[0].label.text = this.thresholdsValues.SystolicMin + ' mmHg';
			this.annotations.yaxis[1].y = this.thresholdsValues.SystolicMax;
			this.annotations.yaxis[1].label.text = this.thresholdsValues.SystolicMax + ' mmHg';

			this.annotations.yaxis[2].y = this.thresholdsValues.DiastolicMin;
			this.annotations.yaxis[2].label.text = this.thresholdsValues.DiastolicMin + ' mmHg';
			this.annotations.yaxis[3].y = this.thresholdsValues.DiastolicMax;
			this.annotations.yaxis[3].label.text = this.thresholdsValues.DiastolicMax + ' mmHg';

			// this.annotations.yaxis[2].y = this.thresholdsValues.DiastolicMin;
			// this.annotations.yaxis[2].label.text = this.thresholdsValues.DiastolicMin + ' mmHg';
			// this.annotations.yaxis[3].y = this.thresholdsValues.DiastolicMax;
			// this.annotations.yaxis[3].label.text = this.thresholdsValues.DiastolicMax + ' mmHg';
          break;

          // case 5:
          // this.annotations.yaxis[0].y = this.thresholdsValues.SystolicMin;
          // this.annotations.yaxis[0].label.text = this.thresholdsValues.SystolicMin + ' mmHg';
          // this.annotations.yaxis[1].y = this.thresholdsValues.SystolicMax;
          // this.annotations.yaxis[1].label.text = this.thresholdsValues.SystolicMax + ' mmHg';
          // break;

        case 6:
          if (this.unitSystem.concentration === 'mg/dl') {
            this.annotations.yaxis[0].y = this.thresholdsValues.BloodSugarLevelMin * 18.018;
            this.annotations.yaxis[1].y = this.thresholdsValues.BloodSugarLevelMax * 18.018;
            this.annotations.yaxis[0].label.text = this.annotations.yaxis[0].y.toFixed(2) + ' mg/dl';
            this.annotations.yaxis[1].label.text = this.annotations.yaxis[1].y.toFixed(2) + ' mg/dl';
          } else {
            this.annotations.yaxis[0].y = this.thresholdsValues.BloodSugarLevelMin;
            this.annotations.yaxis[1].y = this.thresholdsValues.BloodSugarLevelMax;
            this.annotations.yaxis[0].label.text = this.thresholdsValues.BloodSugarLevelMin + ' mmol/l';
            this.annotations.yaxis[1].label.text = this.thresholdsValues.BloodSugarLevelMax + ' mmol/l';
          }
          break;

        case 7:
          if (this.unitSystem.concentration === 'mg/dl') {
            this.annotations.yaxis[0].y = this.thresholdsValues.CholesterolLevelMin * 35;
            // this.annotations.yaxis[0].y = this.thresholdsValues.CholesterolLevelMin * 18.018;
            // this.annotations.yaxis[1].y = this.thresholdsValues.CholesterolLevelMax * 18.018;
            this.annotations.yaxis[1].y = this.thresholdsValues.CholesterolLevelMax * 43.64;
            this.annotations.yaxis[0].label.text = this.annotations.yaxis[0].y.toFixed(2) + ' mg/dl';
            this.annotations.yaxis[1].label.text = this.annotations.yaxis[1].y.toFixed(2) + ' mg/dl';
          } else {
            this.annotations.yaxis[0].y = this.thresholdsValues.CholesterolLevelMin;
            this.annotations.yaxis[1].y = this.thresholdsValues.CholesterolLevelMax;
            this.annotations.yaxis[0].label.text = this.thresholdsValues.CholesterolLevelMin + ' mmol/l';
            this.annotations.yaxis[1].label.text = this.thresholdsValues.CholesterolLevelMax + ' mmol/l';
          }

          break;

        default:
          break;
      }
    },

    toggleThresholds () {
		if (this.deviceType !== 3) {
			if (!this.thresholdToggle) {
				this.$refs.graph.addYaxisAnnotation(this.annotations.yaxis[0], true);
				this.$refs.graph.addYaxisAnnotation(this.annotations.yaxis[1], true);
				// this.$refs.graph.addPointAnnotation(this.annotations.)
				// 	this.annotations.yaxis = this.thresholdsData.yaxis;
			} else {
				this.$refs.graph.removeAnnotation('MaxThreshold');
				this.$refs.graph.removeAnnotation('MinThreshold');
			}
		} else {
			if (!this.thresholdToggle) {
				this.$refs.graph.addYaxisAnnotation(this.annotations.yaxis[0], true);
				this.$refs.graph.addYaxisAnnotation(this.annotations.yaxis[1], true);
				this.$refs.graph.addYaxisAnnotation(this.annotations.yaxis[2], true);
				this.$refs.graph.addYaxisAnnotation(this.annotations.yaxis[3], true);
				// this.$refs.graph.addPointAnnotation(this.annotations.)
				// 	this.annotations.yaxis = this.thresholdsData.yaxis;
			} else {
				this.$refs.graph.removeAnnotation('MaxThreshold');
				this.$refs.graph.removeAnnotation('MinThreshold');
				this.$refs.graph.removeAnnotation('MaxThreshold1');
				this.$refs.graph.removeAnnotation('MinThreshold1');
			}
		}
    },

    getExtremeValues (thresholds) {
      let extremeUpperThreshold = 0;
      let extremeLowerThreshold = 0;

      // const deviceType = this.graphData[0].device_type;
      // switch (deviceType) {
      //   case '1':
      //     extremeUpperThreshold = (thresholds.defaults[1].y > thresholds.minMax[0].y) ? thresholds.defaults[1].y : thresholds.minMax[0].y;
      //     extremeLowerThreshold = (thresholds.defaults[0].y < thresholds.minMax[1].y) ? thresholds.defaults[0].y : thresholds.minMax[1].y;
      //
      //     extremeLowerThreshold -= extremeLowerThreshold * 0.05;
      //     extremeUpperThreshold += extremeUpperThreshold * 0.05;
      //     break;
      //
      //   case '2':
      //     extremeUpperThreshold = (thresholds.defaults[1].y > thresholds.minMax[0].y) ? thresholds.defaults[1].y : thresholds.minMax[0].y;
      //     extremeLowerThreshold = (thresholds.defaults[0].y < thresholds.minMax[1].y) ? thresholds.defaults[0].y : thresholds.minMax[1].y;
      //
      //     extremeLowerThreshold -= extremeLowerThreshold * 0.05;
      //     extremeUpperThreshold += extremeUpperThreshold * 0.05;
      //     break;
      //
      //   case '3':
      //     extremeUpperThreshold = (thresholds.defaults[1].y > thresholds.minMax[0].y) ? thresholds.defaults[1].y : thresholds.minMax[0].y;
      //     extremeLowerThreshold = (thresholds.defaults[0].y < thresholds.minMax[1].y) ? thresholds.defaults[0].y : thresholds.minMax[1].y;
      //
      //     extremeLowerThreshold -= extremeLowerThreshold * 0.05;
      //     extremeUpperThreshold += extremeUpperThreshold * 0.05;
      //     break;
      //
      //   case '5':
      //     extremeUpperThreshold = (thresholds.defaults[1].y > thresholds.minMax[0].y) ? thresholds.defaults[1].y : thresholds.minMax[0].y;
      //     extremeLowerThreshold = (thresholds.defaults[0].y < thresholds.minMax[1].y) ? thresholds.defaults[0].y : thresholds.minMax[1].y;
      //
      //     extremeLowerThreshold -= extremeLowerThreshold * 0.05;
      //     extremeUpperThreshold += extremeUpperThreshold * 0.05;
      //     break;
      //
      //   case '6':
      //     extremeUpperThreshold = (thresholds.defaults[1].y > thresholds.minMax[0].y) ? thresholds.defaults[1].y : thresholds.minMax[0].y;
      //     extremeLowerThreshold = (thresholds.defaults[0].y < thresholds.minMax[1].y) ? thresholds.defaults[0].y : thresholds.minMax[1].y;
      //
      //     extremeLowerThreshold -= extremeLowerThreshold * 0.05;
      //     extremeUpperThreshold += extremeUpperThreshold * 0.05;
      //     break;
      //
      //   case '7':
      //     extremeUpperThreshold = (thresholds.defaults[1].y > thresholds.minMax[0].y) ? thresholds.defaults[1].y : thresholds.minMax[0].y;
      //     extremeLowerThreshold = (thresholds.defaults[0].y < thresholds.minMax[1].y) ? thresholds.defaults[0].y : thresholds.minMax[1].y;
      //
      //     extremeLowerThreshold -= extremeLowerThreshold * 0.05;
      //     extremeUpperThreshold += extremeUpperThreshold * 0.05;
      //     break;
      //
      //   default:
      //     extremeLowerThreshold = 0;
      //     extremeUpperThreshold = 100;
      //     break;
      // }

      extremeUpperThreshold = (thresholds.defaults[1].y > thresholds.minMax[0].y) ? thresholds.defaults[1].y : thresholds.minMax[0].y;

      extremeLowerThreshold = (thresholds.defaults[0].y < thresholds.minMax[1].y) ? thresholds.defaults[0].y : thresholds.minMax[1].y;

      extremeLowerThreshold -= extremeLowerThreshold * 0.05;
      extremeUpperThreshold += extremeUpperThreshold * 0.05;

      return [extremeLowerThreshold, extremeUpperThreshold];
    },

    filterChange () {
      this.periodData = true;
      this.rerender = !this.rerender;
    },
  },
};
</script>

<style>
/* .apexcharts-tooltip {
		background: #f3f3f3;
		color: orange;
	} */
</style>
